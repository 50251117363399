<template>
    <div class="no-data">
        <img src="@/assets/img/download.png" alt="">
        <div class="text">暂无数据&nbsp;...</div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less">
    .no-data{
        position: fixed;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        z-index: 999;
        img{
            opacity: .6;
        }
        .text{
            text-align: center;
            color:#969697
        }
    }
</style>
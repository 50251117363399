<template>
    <div class="my-btn">
        <van-button v-if="!change" @click="$emit('onSubmit')">{{btnStr}}</van-button>
        <div class="btn" v-else>
            <div class="btn_left" @click="$emit('onCancel')">取消</div>
            <div class="btn_right" @click="$emit('onSubmit')">保存</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        change: {
            type: Boolean,
            default: false
        },
        btnStr: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var.scss';
    .my-btn{
        width: 100%;
        position: fixed;
        bottom: 0px;
        background-color: #fff;
        padding: 10px 0;
        .van-button{
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            width: 80%;
            border-radius: 28px;
            box-shadow: 0 0 6px 3px $boxShadow;
            background-color: $main;
            color: #fff;
            border: 0;
        }
        .btn{
            display: flex;
            flex-direction: row;
            line-height: 44px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            width: 80%;
            box-shadow: 0 0 6px 3px $boxShadow;
            border-radius: 28px;
            &_left{
                width: 50%;
                color:$main;
                background: #fff;
                text-align: center;
                border-radius: 28px 0 0 28px;
            }
            &_right{
                width: 50%;
                color: #fff;
                background: $main;
                text-align: center;
                border-radius: 0 28px 28px 0;
            }
        }
    }

</style>
<style lang="scss">
    @import '~@/assets/css/var';
</style>

<template>
    <div id="app" :style="style">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>

        <template v-if="!$route.meta.keepAlive">
            <router-view></router-view>
        </template>

        <van-tabbar v-model="active" v-show="show" @change="goPage">
            <van-tabbar-item v-for="item in pages" :key="item.name" :name="item.name" :badge="item.badge">{{item.value}}
                <template #icon>
                    <van-icon v-if="item.icon" :name="item.icon" />
                    <i v-else class="iconfont" :class="item.iconfont"></i>
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
    import variable from '@/utils/variable';
    export default {
        name: 'App',
        data() {
            return {
                docmHeight: document.documentElement.clientHeight ||document.body.clientHeight,
                showHeight: document.documentElement.clientHeight ||document.body.clientHeight,
                hideshow:true, //显示或者隐藏footer
                loaded: false,
                active: '',
                pages: variable.outPages,
                show: true,
                roadTime: 0,
                projectTime: 0,
                roadDate:'',
                roadTimer: null,
            }
        },
        created() {
            // console.log(this.$route.query)
            if(typeof this.$route.query.personType === 'object'){
                if(this.$route.query.personType[0] === 'MEMBER'){
                    this.$help.storeTemp.set('personType', 0)
                    this.$help.token.set(`Bearer ${this.$route.query.data}`)
                } 
                if(this.$route.query.personType[0] === 'COACH'){
                    this.$help.storeTemp.set('personType', 1)
                    this.$help.store.set('COACH', `Bearer ${this.$route.query.data}`)
                } 
                if(this.$route.query.personType[0] === 'PERSON'){
                    this.$help.storeTemp.set('personType', 2)
                    this.$help.store.set('PERSON', `Bearer ${this.$route.query.data}`)
                } 
            }else{
                if(this.$route.query.personType === 'MEMBER'){
                    this.$help.storeTemp.set('personType', 0)
                    this.$help.token.set(`Bearer ${this.$route.query.data}`)
                } 
                if(this.$route.query.personType === 'COACH'){
                    this.$help.storeTemp.set('personType', 1)
                    this.$help.store.set('COACH', `Bearer ${this.$route.query.data}`)
                } 
                if(this.$route.query.personType === 'PERSON'){
                    this.$help.storeTemp.set('personType', 2)
                    this.$help.store.set('PERSON', `Bearer ${this.$route.query.data}`)
                } 
            }
            if(!this.$help.storeTemp.get('personType')){
                this.$help.storeTemp.set('personType', 0)
            }
            // 控制当路由发送变化的时候，底部的显示
            window.eventBus.$on('page', page => {
                if (variable.outPage[page]) {
                    this.active = page
                    this.show = true
                } else {
                    this.show = false
                }
            })
            window.eventBus.$emit('page', this.$route.name)
        },
        watch: {
            //监听显示高度
            showHeight:function() {
                if(this.docmHeight > this.showHeight){
                    //隐藏
                    this.hideshow=false
                }else{
                    //显示
                    this.hideshow=true
                }
            }
        },
        computed: {
            style() {
                if (this.show) {
                    return {
                        paddingBottom: '50px'
                    }
                }
                return {}
            }
        },
        methods: {
            goPage(name) {
                this.$router.push({name})
            },
           
        },
        mounted(){
            window.onresize = ()=>{
                return(()=>{
                    this.showHeight = document.documentElement.clientHeight || document.body.clientHeight;
                })()
            }
        }
    }
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .common {
        &-list {
            padding: 0 .4rem;
        }
        &-item {
            position: relative;
            display: flex;
            background: #fff;
            margin-top: .4rem;
            //box-shadow: 0 3px 6px rgba(58, 146, 235, 0.1);
            padding:10px;
            .van-image {
                width: 100px;
                height: 100px;
            }
            .van-icon-cross {
                position: absolute;
                top: 10px;
                right: 10px;
                color: $red;
                font-size: 16px;
            }
            &__right {
                display: flex;
                flex-direction: column;
                flex: 1;
                padding: 8px 10px 3px 10px;

                .title {
                    color: #666;
                    font-weight: bold;
                    line-height: 20px;
                    padding-left: unset;
                    //height: 40px;
                    overflow: hidden;
                    .van-tag {
                        margin-right: 6px;
                    }
                }
                .assist {
                    color: #999;
                    font-size: 12px;
                }
            }
        }
    }
    .common-item--parent {
        background-color:$main;
        //background-image: radial-gradient($pink, $pink, $red);
        box-shadow:0 0 9px 3px #c1d2f3;
        .common-item {
            border-top-right-radius: 70px;
            
        }
        .common-item__right {
            padding-right: 30px;
            width: 260px;
            position: relative;
        }
        .van-icon-cross {
            color: #fff;
            right: 5px;
            top: 5px;
            font-size: 13px;
        }
    }
    .van-popup--bottom{
        .van-popup__close-icon--top-right {
            color:rgb(204, 4, 4);
        }   
    }
    .van-hairline--top-bottom{
        z-index:888;
    }
    .van-sticky{
        z-index:999;
    }
    .van-tabbar{
        &-item{
            //padding: 10px 20px;
            color:$main;
            margin: unset !important;
            &__icon{
                padding: 0 0 3px;
                margin-bottom: unset;
            }
            .iconfont{
                font-size: 22px;
            }
            &--active{
                background-color: $main;
                color: #fff !important;
                border-radius: 0 45px 0 45px;
            }

        }        
    }
    .van-uploader__preview{
        border: 1px dashed #ededed;
    }
    .van-image-preview{
        .van-image{
            background-color: #fff;
        }
    }
    // .van-notify--success{
    //     background-color: $main !important;
    // }

    .my-search{
        height: 60px;
        .van-search__content{
            height: 100%;
            .van-cell{
                display: flex;
                align-items: center;
                &__value{
                    color: $main;
                    input{ 
                        color: $main;
                    }
                    ::-webkit-input-placeholder{
                        color: $main;
                        opacity: .3;
                    }
                }
            }
            .van-icon{
                color: $main;
            }
        }
    }

</style>


import Vue from 'vue'
import Vuex from 'vuex'
import './assets/css/reset.scss'
import 'vant/lib/index.css'
import App from './App.vue'
import Vant, {Lazyload, Rate} from 'vant'
import router from './utils/router'
import storeBase from './utils/store'
import './utils/component'
import './utils/directives'
import api from './utils/api'
import help from './utils/help'
import variable from './utils/variable'

Vue.use(Vant)
Vue.use(Vuex)
Vue.use(Lazyload)
Vue.use(Rate)

Vue.config.productionTip = false
window.eventBus = new Vue()

Vue.prototype.$api = api;
Vue.prototype.$help = help;
Vue.prototype.$variable = variable;
//const urlRoot = 'mobile/V1/mobile'
Vue.prototype.getImg = function(key){
  let img = key || ''
  if (img.indexOf('*##*')> -1) {
    img = img.split('*##*')[0]
  }
  return key ? `/horse/common/sys/attachment/viewAttachmentStream?attachmentId=${img}&token=${help.store.get('mobile')}` : '';
}
//pdf
// Vue.prototype.getPdf = function(key){
//   return key ? `/api/${urlRoot}/sysAttachmentStream?sysAttachmentId=${key}&token=${help.store.get('mobile')}` : '';
// }

Vue.filter('formatInterval', function (source) {
  if (source) {
    const start = new Date(source.billDate.replace(/-/g, '/'));
    const end = new Date(new Date(source.billDate.replace(/-/g, '/')).setMinutes(start.getMinutes() + source.videoTimes))

    return `${start.Format('hh:mm')} - ${end.Format('hh:mm')}`
  }
})

export default new Vue({
  store: new Vuex.Store(storeBase),
  render: h => h(App),
  router
}).$mount('#app')

<template>
    <div class="video-item" @click="$emit('goDetails')">
       <div class="content">
           <div class="left">
                <van-image class="my-image" fit="cover" :src="getImg(item.picOne)" alt="">
                    <template v-slot:error><van-image class="my-image" fit="cover" :src="imgUrl1" alt=""/></template>
                </van-image>
                <!-- <div class="icon">
                    <van-icon name="play" /> 12:05
                </div> -->
                <!-- <i class="iconfont icon-xiazai"></i> -->
           </div>
           <div class="right">
               <div class="title">
                   {{item.matchName}}
               </div>
               <!-- <div class="see">
                   观看{{123}}
               </div> -->
               <div class="date">
                   <span>{{item.begDate}}</span>
                   <span> ~</span>
                   <span>{{item.endDate}}</span>
               </div>
               <div class="address">
                   <van-icon name="location" /><span>{{item.address}}</span>
               </div>
           </div>
       </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        item: {
            type: Object,
            default: () =>{
                return {}
            }
        }

    },
    data() {
        return {
            imgUrl1: require('@/assets/img/horse1.jpg'),
        }
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .video-item{
        margin-top: 20px;
        background-color: #fff;
        position: relative;
        overflow: hidden;
        box-shadow: 0 0 9px 3px $boxShadow;
        border-radius: 5px;
        padding: 10px;
        .title{
            font-weight: bold;
        }
        .content{
            font-size: 13px;
            display: flex;
            justify-content: space-between;
            .left{
                flex: 1;
                position: relative;
                .my-image{
                    width: 150px;
                    height: 100px;
                }
                .icon{
                    position: absolute;
                    bottom: 5px;
                    right: 5px;
                    color: #fff;
                }
            }
            .right{
                padding-left: 10px;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .date{
                    color: $gray;
                    font-size: 12px;
                }
                .address{
                    font-size: 12px;
                    color: $gray;
                   i{
                       color: $main;
                   } 
                }
                .title{
                    margin-bottom: 10px;
                    height: 35px;
                    display: -webkit-box;/**对象作为伸缩盒子模型展示**/
                    -webkit-box-orient: vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
                    -webkit-line-clamp: 2;/**显示的行数**/
                    overflow: hidden;/**隐藏超出的内容**/
                }
                .see{
                    color: $gray;
                }
            }
        }
        .icon{
            .van-icon-arrow{
                position: absolute;
                top: 48%;
                right: 46px;
                padding: 3px;
                border-radius: 50%;
                transform: translateY(-50%);
                color: $main;
                background-color: #deedfc;
            }
        }
    }
</style>
<template>
    <div class="my-map">
        <div id="my-map" ref="myMap">  </div>
    </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
    props: {
        lineList: {
            type: Array,
            default() {
                return []
            }
        },
        position: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            map: null,
            AMap: {},
            center: [115.89, 28.68],
            zoom: 15.5,
        }
    },
    methods: {
        initMap() {
            AMapLoader.load({
                key: "8b1551a262e22d0b5dbfe29046fe45de", // 申请好的Web端开发者Key，首次调用 load 时必填
                version: "2.0",
                plugins: ["AMap.Geolocation", "AMap.AutoComplete"], //插件列表
            })
            .then((AMap) => {
                this.AMap = AMap;
                this.map = new this.AMap.Map("my-map", {
                    zoom: this.zoom,
                    center: this.center,
                    resizeEnable: true,
                });
                //定位
                //this.map.addControl(this.mapPosition())

                //画线
                if(this.lineList.length !== 0){
                    this.pathLength()
                }

                //画点
                if(this.position.length !== 0){
                    this.addMarker()
                }  
                
            })
            .catch((e) => {
            console.log(e);
            });
        },
        pathLength() {  
            let newarrList = []
            this.lineList.map( (parent, i) => {
                let newArr = []
                parent.list.map( child =>{
                    if(child.x && child.y){
                        newArr.push(new this.AMap.LngLat(child.x, child.y))
                    }
                })
                newarrList.push({rank: i, arr: newArr})
            })
            //定义折线对象
            newarrList.map((ele, i) =>{
                new this.AMap.Polyline({
                path: ele.arr, //设置折线的节点数组
                strokeColor: "#07c160",
                strokeOpacity: .8,
                strokeWeight: 5,
                strokeDasharray: [10, 5],
                }).setMap(this.map);
                    this.map.add(new this.AMap.Text({
                    position: new this.AMap.LngLat(this.lineList[i].list[this.lineList[i].list.length-1].x,this.lineList[i].list[this.lineList[i].list.length-1].y),
                    text: "路程长" + Math.round(this.AMap.GeometryUtil.distanceOfLine(ele.arr)) + "米",
                    offset: new this.AMap.Pixel(0, 0),
                }))
            })
            this.map.setFitView();
        },
        addMarker() {
            let marker = new this.AMap.Marker({
                icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
                //[116.405467, 39.907761]
                position: this.position,
                anchor:'bottom-center'
            });
            this.map.add(marker);
            this.map.setFitView();
        },
    },
    created() {
        this.initMap()
    },
    mounted() {
        //this.pathLength()
    }
};

</script>

<style lang="scss">
    .my-map{
        #my-map{
            height: 500px;
            .amap-markers{
                .amap-marker{
                    .amap-icon{
                        width: 20px;
                        height: 20px;
                    }
                 
                }
            }
        }
    }
</style>
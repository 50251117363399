import Vue from 'vue'
import VueRouter from 'vue-router'
import help from './help'
import variable from './variable'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// 登录注册相关页面
const routesAuth = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/login/index'),
        meta: {
            title: '登录',
        }
    },
]

const horse = [
    {
        path: '/search',
        name: 'search',
        component: () => import('@/pages/Horse/Search'),
        meta: {
            title: '搜索',
        }
    },
    {
        path: '/appointment',
        name: 'appointment',
        component: () => import('@/pages/Horse/Appointment'),
        meta: {
            title: '校区',
        }
    },
    {
        path: '/coach',
        name: 'coach',
        component: () => import('@/pages/Horse/CoachDetails'),
        meta: {
            title: '教练简介',
        }
    },
    {
        path: '/trailer',
        name: 'trailer',
        component: () => import('@/pages/Horse/Trailer'),
        meta: {
            title: '赛事预告',
        }
    },
    {
        path: '/playback',
        name: 'playback',
        component: () => import('@/pages/Horse/PlayBack'),
        meta: {
            title: '精彩回放',
        }
    },
    {
        path: '/trailer/details',
        name: 'trailerdetails',
        component: () => import('@/pages/Horse/trailerDetails'),
        meta: {
            title: '赛事预告详情',
        }
    },
    {
        path: '/palyback/details',
        name: 'palybackdetails',
        component: () => import('@/pages/Horse/playBackDetails'),
        meta: {
            title: '精彩瞬间详情',
        }
    },
    {
        path: '/horse/details',
        name: 'horsedetails',
        component: () => import('@/pages/Horse/HorseDetails'),
        meta: {
            title: '马场详情',
        }
    },
    {
        path: '/horse/appcourse',
        name: 'appcourse',
        component: () => import('@/pages/Horse/AppCourse'),
        meta: {
            title: '预约课程',
        }
    }
]

const mall = [
    {
        path: '/allshop',
        name: 'allshop',
        component: () => import('@/pages/Mall/AllShop'),
        meta: {
            title: '搜索',
        }
    },
    {
        path: '/shopping',
        name: 'shopping',
        component: () => import('@/pages/Mall/shopping'),
        meta: {
            title: '购物车',
        }
    },
    {
        path: '/ordersubmit',
        name: 'ordersubmit',
        component: () => import('@/pages/Mall/OrderSubmit'),
        meta: {
            title: '提交订单',
        }
    },
    {
        path: '/mall/detail',
        name: 'malldetail',
        component: () => import('@/pages/Mall/detail'),
        meta: {
            title: '详情',
        }
    },
]

const card = [
    {
        path: '/course/detail',
        name: 'coursedetail',
        component: () => import('@/pages/Course/detail'),
        meta: {
            title: '详情',
        }
    },
]

const coach = [
    {
        path: '/coach/memberdetail',
        name: 'memberdetail',
        component: () => import('@/pages/center/Coach/memberDetail'),
        meta: {
            title: '会员信息',
        }
    },
    {
        path: '/coach/singledetail',
        name: 'singledetail',
        component: () => import('@/pages/center/Coach/singleDetail'),
        meta: {
            title: '成单明细',
        }
    },
]

const person = [
    {
        path: '/person/detailclass',
        name: 'detailclass',
        component: () => import('@/pages/center/person/detailClass'),
        meta: {
            title: '上课明细',
        }
    },
    {
        path: '/person/memberlist',
        name: 'memberlist',
        component: () => import('@/pages/center/person/memberList'),
        meta: {
            title: '会员列表',
        }
    },
    {
        path: '/person/memberclass',
        name: 'memberclass',
        component: () => import('@/pages/center/person/memberClass'),
        meta: {
            title: '会员信息',
        }
    },
    {
        path: '/person/coachdetail',
        name: 'coachdetail',
        component: () => import('@/pages/center/person/coachDetail'),
        meta: {
            title: '成单明细',
        }
    },
    {
        path: '/person/rank',
        name: 'personrank',
        component: () => import('@/pages/center/person/Ranking'),
        meta: {
            title: '顾问排名',
        }
    },
    {
        path: '/person/results',
        name: 'personresults',
        component: () => import('@/pages/center/person/Results'),
        meta: {
            title: '业绩',
        }
    },
    
]

const Center = [
    {
        path: '/allcourse',
        name: 'allcourse',
        component: () => import('@/pages/center/AllCourse'),
        meta: {
            title: '课程',
        }
    },
    {
        path: '/infoset',
        name: 'infoset',
        component: () => import('@/pages/center/Info/Set'),
        meta: {
            title: '修改',
        }
    },
    {
        path: '/center/realname',
        name: 'realname',
        component: () => import('@/pages/center/Info/RealName'),
        meta: {
            title: '实名认证',
        }
    },
    {
        path: '/center/address',
        name: 'address',
        component: () => import('@/pages/center/Info/Address'),
        meta: {
            title: '收货地址',
        }
    },
    {
        path: '/center/myqr',
        name: 'myqr',
        component: () => import('@/pages/center/Info/MyQr'),
        meta: {
            title: '我的二维码',
        }
    },
    {
        path: '/center/invitation',
        name: 'invitation',
        component: () => import('@/pages/center/Students/invitation'),
        meta: {
            title: '邀请',
        }
    },
    {
        path: '/center/bill',
        name: 'bill',
        component: () => import('@/pages/center/Students/Bill'),
        meta: {
            title: '我的账单',
        }
    },
    {
        path: '/center/relation',
        name: 'relation',
        component: () => import('@/pages/center/Students/Relation'),
        meta: {
            title: '亲属关系',
        }
    },
    {
        path: '/center/charge',
        name: 'charge',
        component: () => import('@/pages/center/Students/Charge'),
        meta: {
            title: '充值',
        }
    },
    {
        path: '/center/voucher',
        name: 'voucher',
        component: () => import('@/pages/center/Students/CardVoucher'),
        meta: {
            title: '我的课程套餐',
        }
    },
    {
        path: '/center/rank',
        name: 'rank',
        component: () => import('@/pages/center/Coach/Ranking'),
        meta: {
            title: '排名',
        }
    },
    {
        path: '/center/order',
        name: 'centerorder',
        component: () => import('@/pages/center/GoodsOrder'),
        meta: {
            title: '订单',
        }
    },
    {
        path: '/center/integral',
        name: 'integral',
        component: () => import('@/pages/center/Students/Integral'),
        meta: {
            title: '积分明细',
        }
    },
    {
        path: '/center/students/close',
        name: 'close',
        component: () => import('@/pages/center/Students/CourseClose'),
        meta: {
            title: '关单',
        }
    },
    {
        path: '/center/students/single',
        name: 'single',
        component: () => import('@/pages/center/Students/CourseSingle'),
        meta: {
            title: '重新支付',
        }
    },
    {
        path: '/center/pay',
        name: 'pay',
        component: () => import('@/pages/center/pay'),
        meta: {
            title: '收银台',
        }
    },
    {
        path: '/center/courseorder',
        name: 'courseorder',
        component: () => import('@/pages/center/CourseOrder'),
        meta: {
            title: '课程订单',
        }
    },
]


const routes = [
    { path: '/', redirect: '/horsefarm' },
    ...routesAuth,
    ...horse,
    ...Center,
    ...mall,
    ...card,
    ...person,
    ...coach

]


const router = new VueRouter({
    routes: routes.concat(
        variable.outPages.map(item => {
            return {
                path: item.path ? item.path : `/${item.name}`,
                name: item.name,
                component: item.component,
                meta: {
                    title: item.value,
                    keepAlive: item.keepAlive
                }
            }
        })
    ),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition && to.meta.keepAlive) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
})

router.beforeEach((to, from, next) => {
    to.meta.isRefresh = false
    window.eventBus.$emit('page', to.name)
    setTimeout(() => {
        window.eventBus.$emit('page', to.name)
    }, 50)
    to.meta.title && help.setTitle(to.meta.title)
    next()
})
router.afterEach(() => {
    // const el = document.getElementById("app").children[0];
    // if (el && el.scrollIntoView) {
    //     el.scrollIntoView()
    // }
})




export default router
<style lang="scss">
    @import '~@/assets/css/var';
    .my-add {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 10px;
        bottom: 4vh;
        box-shadow: 0 3px 6px rgba(58, 146, 235, 0.1);
        border-radius: 50%;
        font-weight: bold;
        background: #fff;
        z-index: 999;
        height: 54px;
        width:54px;
        box-shadow:0 0 9px 3px #c1d2f3;
        .van-icon-plus {
            color: $main;
            font-size: 24px;
            font-weight: bold;
        }
        .van-icon-exchange{
            color: $main;
            font-size: 20px;
            font-weight: 700;
        }
    }
</style>

<template>
    <div class="my-add" @click="$emit('add')">
        <van-icon v-show="!showSign" name="plus"  />
        <van-icon v-show="showSign" name="exchange" />
    </div>
</template>

<script>

export default({
     props:{
        showSign:{
            type:Boolean,
            default: false,
        }
    },
})
</script>



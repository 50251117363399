<template>
    <div class="my-title">
        <div class="add_info">
            <span v-if="showChange"><i class="iconfont icon-xiangqing"></i>{{title}}</span>
            <span v-else><i class="iconfont icon-tupian"></i>{{msg}}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showChange: {
            type: Boolean,
            default: true
        },
        msg: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: '记录详情'
        }
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var.scss';
    .my-title{
        width:100%; 
        position: fixed;
        top:0;
        z-index: 999;
        .add_info{
            i{
                padding-right: 5px;
                font-weight: 700;
            }
            span{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            width: 35%;
            height: 44px;
            background-color: $main;
            color:#fff;
            border-radius: 0 28px 28px 0;
            text-align: center;
            line-height: 44px;
            font-weight: 700;
            box-shadow:0 0 9px 3px $boxShadow;
        }
    }
</style>
<template>
    <div class="no-more_pic">
        <img src="@/assets/img/no_more.png" alt="">
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less">
    .no-more_pic{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:10px;
        img{
            height: 80px;
            width: 100px;
            margin-bottom:10px;
        }
    }
</style>
export default {
    data() {
        return {
            first: false,
            page: 0,
            rows: 10,
            list: [],

            loading: false,
            loading1: false,
            finished: false,
            finished1: false,
            refreshing: false,
            showNoMore: false,
            showData: false,
            isLoading: false,
            total:0,
			//sort:{'ts': 'desc'},
            errorNum: 0,
        }
    },
    activated() {
        // first 主要是解决首次加载两次的问题
        // if (this.first) {
        //     setTimeout(() => {
        //         const scrollTop =  document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
        //         if (!scrollTop) {
        //             this.init && this.init();
        //             this.initList();
        //         }
        //     })
        // }
        // this.first = true
    },
    methods: {
        initList() {
            this.page = 0;
            this.list = [];
            this.loading = false;
            this.loading1 = true;
            this.finished = false;
            this.finished1 = false;
            this.isLoading = false;
            this.onLoad1();
        },
        onLoad() {
            setTimeout(() => {
                const list = []
                for (let i = 0; i < 10; i++) {
                    list.push({id: Math.random()});

                }
                //console.log(...list)
                //debugger
                this.list = [...this.list,...list]
                // 加载状态结束
                this.loading1 = false;
                this.isLoading = false;
                // 数据全部加载完成
                if (this.list.length >= 40) {
                    this.finished1 = true;
                }
            }, 1500);
        },
        onLoad1() {
            //{"page":{"current":1,"size":30},"model":{},"filters":{}}
            if (this.api) {
                this.page += 1
                //debugger 
                this.loading = true;
                this.$api.post(this.api, {
                    page: {
                        current: this.page,
                        pageSize: this.rows,
                        orders: this.orders
                    },
                    model: this.model,
                    filters:this.filters
                })
                    .then(res => {
                        //debugger 
                        //console.log(res)
                        if(res.total === 0){
                            this.showData = true
                            this.showNoMore = false
                        }else{
                            this.showData = false 
                            this.showNoMore = true
                        }
                        if (res.records.length < this.rows) {
                            this.finished = true
                        }
                        if (this.page === 1) { 
                            this.list = this.afterLoadList ? this.afterLoadList(res.records) : res.records
                        } else {
                            this.list = this.afterLoadList ? [...this.list, ...this.afterLoadList(res.records)] : [...this.list, ...res.records]
                        }   
                        //console.log(this.list)
                    })
                    .catch(() => {
                        this.finished = true
                    })
                    .finally(() => {
                        this.loading = false  
                        this.isLoading = false;        
                    })
            } else {
                this.finished = true;
            }
        },
        onRefresh() {
            this.page = 0;
            this.list = [];
            this.loading = false;
            this.loading1 = true;
            this.finished = false;
            this.finished1 = false;
            this.isLoading = true;
            this.onLoad1();
        }
    },
}

import Horse from '@/pages/Horse/index';
import Course from '@/pages/Course/index';
import Mall from '@/pages/Mall/index';
import Center from '@/pages/center/index';

const outPages = [
    {
        name: 'horsefarm',
        value: '马场',
        iconfont: 'icon-sharpicons_Horse',
        component: Horse,
        keepAlive: false,
    },
    {
        name: 'course',
        value: '课程套餐',
        iconfont: 'icon-youhuiquan1',
        component: Course,
        keepAlive: false
    },
    {
        name: 'mall',
        value: '商城',
        iconfont: 'icon-shangcheng',
        component: Mall,
        keepAlive: false,
    },
   
    {
        name: 'center',
        value: '我的',
        icon: 'user-circle-o',
        component: Center,
    },
]
const outPage = {}
outPages.forEach(item => {
    outPage[item.name] = item.name
})

export default {
    outPages,
    outPage,
    projectName: '马术',
    successCode: '00000',
    tokenInvalid: ['AU100', 'AU101', 'AU102', 'AU103', 'AU105'],

    version: 'V1.0.0',
}
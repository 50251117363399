<template>
    <div class="my-horse">
        <!-- 头部 -->
        <div class="my-horse__header">
            <van-image class="my-image" fit="cover" :src="imgUrl" alt=""/>
            <div class="header">
                <div class="type" @click="goTrailer">
                    <van-icon size="20" name="video-o" />
                    <span>赛事预告</span>
                </div>
                <div class="type" @click="goPlayBack">
                    <van-icon size="20" name="tv-o" />
                    <span>精彩瞬间</span>
                </div>
            </div>
        </div>
        <van-notice-bar left-icon="volume-o" :text="'马场营业时间：' + operationTime"
        />
        <!-- 搜索 -->
        <van-sticky>
            <div class="my-horse__search">
                <van-search
                    class="my-search"
                    v-model="value"
                    shape="round"
                    placeholder="请输入马场名称"
                    @search="onSearch"
                    >
                </van-search>
            </div>
        </van-sticky>
        <!-- 列表 -->
        <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                @load="onLoad1"
                class="my-list"
                >
                <my-item v-for="item in list" :key="item.id" @goDedail="goDedail(item.id)" :pic-url="item.thumbnailUrl">
                    <div class="info" slot="info">
                        <div class="horse">{{item.corpName}}</div>
                        <div class="address"><van-icon name="location"/>{{item.address}}</div>
                    </div>
                    <div class="appoitment" slot="appoitment">
                        <van-button @click.stop="goAppCourse(item.id)" :disabled="$help.storeTemp.get('personType') !== 0">课程预约</van-button>
                        <!-- <div class="icon">
                            <van-icon name="phone" @click.stop="callPhone(item.phone)"/>
                        </div> -->
                    </div>
                </my-item>
                <no-more v-show="showNoMore && !loading"/>
            </van-list>
        </van-pull-refresh>
        <!-- 暂无数据 -->
        <no-data v-if="showData"/>
    </div>
</template>

<script>
import mixinPage from '@/utils/mixinPage';
export default {
    mixins: [ mixinPage ],
    data() {
        return {
            api: 'common/base/corp/corp-list',
            imgUrl: require('@/assets/img/horse2.jpg'),
            disabled:false,
            value: '',
            model: {},
            operationTime: '暂无'
        }
    },
    methods: {
        getOpenTime() {
            this.$api.post('common/sys/sysProp/sysProp-show', {id: 'operationTime'}).then(res=>{
                if(res.propertyValue) {
                    this.operationTime = res.propertyValue
                }   
            })
        },
        init() {
            this.initList()
        },
        onSearch() {
            this.$set(this.model, 'corpNameLike', this.value)
            this.init()
        },
        // appointment(id) {
        //     this.$router.push({path: '/appointment', query:{
        //         id: id
        //     }})
        // },
        callPhone(phone) {
            window.location.href = 'tel://' + phone;
        },
        goTrailer() {
            this.$router.push('/trailer')
        },
        goPlayBack() {
            this.$router.push('/playback')
        },
        goAppCourse(id) {
            this.$router.push({path: '/horse/appcourse', query: {
                id: id
            }})
        },
        goDedail(id) {
            this.$router.push({path: '/appointment', query: {
                id: id
            }})
        },
    },
    mounted() {
        this.getOpenTime()
        this.model.status = 1
        this.init()
    }
    
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .my-horse{
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
        &__header{
            position: relative;
            .my-image{
                width: 100%;
                height: 150px;
            }
            .header{
                position: absolute;
                display: flex;
                justify-content: center;
                bottom: -20px;
                left: 50%;
                transform: translateX(-50%);
                background-color: #fff;
                width: calc(100vw - 40px);
                border-radius: 19px;
                box-shadow: 0 5px 12px 1px $boxShadow;
                .type{
                    padding: 6px 0;
                    margin: 10px 0;
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-right: 1px solid #ededed;
                    i{
                        color: $main;
                        padding-right: 5px;
                    }
                    &:last-child{
                        border-right: none;
                    }
                }
            }
            margin-bottom: 30px;

        }
        &__search {
            // margin: 40px 0 20px;
            padding: 0 20px 0 8px;
            background-color: #fff;
            .van-search__action{
                color: $main;
            }
        }
        .my-pull-refresh{
            .my-list{
                margin-top: 20px;
                padding: 0 25px;
            }
        }
        .no-data{
            top: 65%;
        }
        #mymap{
            display: none;
        }

    }
</style>
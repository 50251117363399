<template>
    <div class="my-center">
        <div class="my-center__header">
            <div class="avatar">
                <van-uploader accept="image/*" v-model="fileList" :after-read="afterRead">
                    <van-image class="my-image" round fit="cover" :src="getImg(userInfo.picHeadUrl)" alt="">
                        <template v-slot:error><van-image round class="my-image" fit="cover" :src="avatar" alt=""/></template>
                    </van-image>
                </van-uploader>
                <div class="name">
                    {{userInfo.memberName || userInfo.coachName || userInfo.personName || '***'}}<sub>{{personTypeName}}</sub>
                </div>
                <div class="follow" v-if="($help.storeTemp.get('personType') !== 0)">
                    <van-tag round type="primary" @click="onFollow">关联微信</van-tag>
                </div>
            </div>
            <div class="code" v-if="$help.storeTemp.get('personType') !== 0">
                <!-- <van-icon @click="goSet" v-if="$help.storeTemp.get('personType') === 0" name="setting-o" /> -->
                <div @click="goRank">排名</div>
            </div>
        </div>
        <!-- 会员余额积分 -->
        <div class="my-center__course"  v-if="$help.storeTemp.get('personType') === 0">
            <div class="item" @click="goCharge">
                <div class="type">
                    <i class="iconfont icon-jine"></i>
                    <span>余额</span>
                </div>
                <div class="num">
                    ￥{{userInfo.amount || 0}}
                </div>
            </div>
            <div class="item" @click="goVoucher">
                <div class="type">
                    <i class="iconfont icon-youhuiquan1"></i>
                    <span>课程套餐</span>
                </div>
                <div class="num">
                   {{userInfo.cardHours || 0}}
                </div>
            </div>
            <div class="item" @click="goIntegral">
                <div class="type">
                    <i class="iconfont icon-jifen"></i>
                    <span>积分</span>
                </div>
                <div class="num">
                    {{userInfo.integral || 0}}
                </div>
            </div>
        </div>
        <!-- 教练排名 -->
        <div class="my-center__course" v-if="$help.storeTemp.get('personType') === 1">
            <div class="item" @click="goSingle"> 
                <div class="type">
                    <i class="iconfont icon-jine"></i>
                    <span>成单奖</span>
                </div>
                <div class="num">
                    ￥{{userInfo.coachAchievementAmountTotal || 0}}
                </div>
            </div>
            <div class="item">
                <div class="type">
                    <i class="iconfont icon-jine"></i>
                    <span>鞍时费</span>
                </div>
                <div class="num">
                    ￥{{userInfo.coachPriceInTotal || 0}}
                </div>
            </div>
            <!-- <div class="item">
                <div class="type">
                    <i class="iconfont icon-paiming"></i>
                    <span>月排名</span>
                </div>
                <div class="num">
                    20
                </div>
            </div>
            <div class="item">
                <div class="type">
                    <i class="iconfont icon-paiming"></i>
                    <span>年排名</span>
                </div>
                <div class="num">
                    100
                </div>
            </div> -->
        </div>

    
        <!-- 商城订单  -->
        <div class="goods-order" v-if="$help.storeTemp.get('personType') === 0">
            <van-cell title="商城订单" :border="false" value="全部" is-link @click="goOrder(0)"/>
            <div class="order">
                <div class="item">
                    <div class="type" @click="goOrder(1)">
                        <i class="iconfont icon-daizhifu"></i>
                        <span>待支付</span>
                        <van-badge class="my-badge" :content="orderNum.waitPayNumber || 0" max="99"/>
                    </div>
                </div>
                <div class="item">
                    <div class="type" @click="goOrder(2)">
                        <i class="iconfont icon-daifahuo1"></i>
                        <span>待发货</span>
                        <van-badge class="my-badge" :content="orderNum.waitSendGoods || 0" max="99"/>
                    </div>
                </div>
                <div class="item">
                    <div class="type" @click="goOrder(3)">
                        <i class="iconfont icon-daishouhuo"></i>
                        <span>待收货</span>
                        <van-badge class="my-badge" :content="orderNum.deliverGoods || 0" max="99"/>
                    </div>
                </div>
                <!-- <div class="item">
                    <div class="type">
                        <i class="iconfont icon-quanbudingdan1"></i>
                        <span>全部</span>
                        <van-badge class="my-badge" :content="200" max="99"/>
                    </div>
                </div> -->
            </div>
        </div>

        <!-- 课程订单  -->
        <div class="goods-order" v-if="$help.storeTemp.get('personType') === 0">
            <van-cell title="课程订单" :border="false" value="全部" is-link @click="goCourseOrder(0)" />
            <div class="order">
                <div class="item">
                    <div class="type" @click="goCourseOrder(1)">
                        <i class="iconfont icon-daizhifu"></i>
                        <span>待支付</span>
                        <van-badge class="my-badge" :content="courseOrderNum.waitPayNumber || 0" max="99"/>
                    </div>
                </div>
                <div class="item">
                    <div class="type" @click="goCourseOrder(2)">
                        <i class="iconfont icon-daifahuo1"></i>
                        <span>已支付</span>
                        <van-badge class="my-badge" :content="courseOrderNum.waitSendGoods || 0" max="99"/>
                    </div>
                </div>
                <div class="item">
                    <div class="type" @click="goCourseOrder(3)">
                        <i class="iconfont icon-daishouhuo"></i>
                        <span>已关闭</span>
                        <van-badge class="my-badge" :content="courseOrderNum.deliverGoods || 0" max="99"/>
                    </div>
                </div>
            </div>
        </div>

        <van-cell-group :border="false" v-if="$help.storeTemp.get('personType') === 2">
            <van-cell class="my-cell"  title="会员明细" is-link @click="goMemberDetail">
                <template #icon>
                    <van-icon name="points" color="blue"/>
                </template>
            </van-cell>
            <van-cell class="my-cell" icon="shop-o" title="教练成单明细" is-link @click="goCoachDetial">
                <template #icon>
                   <van-icon name="award-o" color="green" />
                </template>
            </van-cell>
            <van-cell class="my-cell" icon="shop-o" title="业绩" is-link @click="goResults">
                <template #icon>
                   <van-icon name="chart-trending-o" color="red" />
                </template>
            </van-cell>
        </van-cell-group>


        <van-cell-group class="my-group" v-if="$help.storeTemp.get('personType') === 0">
            <van-cell class="real-name"  title="我的信息" icon="idcard" to="center/realname" is-link/>
            <van-cell class="relation" title="邀请记录" icon="friends-o" @click="goInvitation" is-link/>
            <van-cell class="qr" title="我的二维码" icon="qr" to='/center/myqr' is-link/>
            <van-cell class="relation" title="我的亲属" icon="cluster-o" @click="goRelation" is-link/>
        </van-cell-group>



        <!-- 我的课程 / 上课信息 -->
        <van-cell :title="orderName" class="my-cell" value="全部" is-link @click="goAllSourse" v-if="$help.storeTemp.get('personType') !== 2"/>
        <!-- 未上课 -->
        <van-swipe class="my-swipe" :show-indicators="false" :width="250" :loop="false" v-if="$help.storeTemp.get('personType') !== 2 && swiperList.length !==0">
            <van-swipe-item v-for="(item, i) in swiperList" :key="i">
                <my-course :item="item" @goToCourse="goToCourse(item)">
                    <div slot="type" v-if="$help.storeTemp.get('personType') === 0">
                        <div class="info coach">
                            <div class="label">教练</div>
                            <div class="value">{{item.coachName}}</div>
                        </div>
                        <div class="info level">
                            <div class="label">级别</div>
                            <div class="value">{{item.coachLevelName}}</div>
                        </div>
                    </div>
                    <div slot="type" v-if="$help.storeTemp.get('personType') === 1">
                        <div class="info coach" @click="goMember(item.memberId, item.id)">
                            <div class="label">姓名</div>
                            <div class="value">{{item.relativeName}}<van-icon name="arrow" /></div>
                        </div>
                        <div class="info level">
                            <div class="label">昵称</div>
                            <div class="value">{{item.memberName}}</div>
                        </div>
                    </div>
                </my-course>
            </van-swipe-item>
        </van-swipe> 
        <!-- 已上课 -->
        <van-swipe class="my-swipe finally" :show-indicators="false" :width="250" :loop="false" v-if="$help.storeTemp.get('personType') !== 2 && swiperListed.length !==0">
            <van-swipe-item v-for="(item, i) in swiperListed" :key="i">
               <my-course :item="item" :complete="true" @goToCourse="goToCourse(item)" @goAppraise="goAppraise(item)" @goComputed="goComputed(item)">
                   <div slot="type" v-if="$help.storeTemp.get('personType') === 0">
                        <div class="info coach">
                            <div class="label">教练</div>
                            <div class="value">{{item.coachName}}</div>
                        </div>
                        <div class="info level">
                            <div class="label">级别</div>
                            <div class="value">{{item.coachLevelName}}</div>
                        </div>
                    </div>
                    <div slot="type" v-if="$help.storeTemp.get('personType') === 1">
                        <div class="info coach" @click="goMember(item.memberId, item.id)">
                            <div class="label">姓名</div>
                            <div class="value">{{item.relativeName}}<van-icon name="arrow" /></div>
                        </div>
                        <div class="info level">
                            <div class="label">昵称</div>
                            <div class="value">{{item.memberName}}</div>
                        </div>
                    </div>
               </my-course>
            </van-swipe-item>
        </van-swipe>

        <div class="center-btn" v-if="$help.storeTemp.get('personType') !== 0">
            <van-button type="primary" round block @click="exit">退出</van-button>
        </div>

        <van-dialog v-model="showCourse" title="上课提示" show-cancel-button cancelButtonText="已超时" confirmButtonText="去上课" @confirm="diaConfirm" @cancel="diaCancel">
            <div class="dialogContent">确认去上课或课程超时</div>
            <van-icon name="cross" @click="showCourse=false" />
        </van-dialog>

        <van-dialog v-model="showAppraise" title="评价教练" show-cancel-button @confirm="changeRecode" @cancel="onCancelRecode" :before-close="beforeClose">
            <van-cell-group>
                <van-field center clearable type="textarea" v-model="appraise" placeholder="请输入评价教练内容"/>
                <van-field class="my-cell" center label="能力分值(满分100)" required type="number" v-model="abilityScore" placeholder="请输入教练能力分值"/> 
                <van-field class="my-cell" center label="态度分值(满分100)" required type="number" v-model="attitudeScore" placeholder="请输入教练态度分值"/>
            </van-cell-group>
        </van-dialog>

        <van-dialog v-model="coachEvaluate.show" title="课程评价" show-cancel-button @confirm="changeEvaluate" @cancel="onCancelRecode" :before-close="beforeClose">
            <van-cell-group>
                <van-field center clearable type="textarea" v-model="coachEvaluate.content" placeholder="请输入教练对课程内容评价"/>
                <van-cell class="my-cell" value="课程评分">
                    <template #right-icon>
                        <van-rate v-model="coachEvaluate.value" :size="25" icon="star" 
                            void-icon="star-o" color="#ffd21e" void-color="#ffd21e"/>
                    </template>
                </van-cell>
            </van-cell-group>
        </van-dialog>

    </div>
</template>

<script>
export default {
    data() {
        return {
            personTypeName: '',
            updateApi: '',
            avatar: require('@/assets/img/avatar.jpeg'),
            swiperList: [],
            swiperListed: [],
            orderNum: {},
            courseOrderNum: {},
            selectItem: {},
            orderName: '',
            showCourse: false,
            showSingle: false,
            showAppraise: false,
            flag: false,
            coachEvaluate: {
                show: false,
                content: '',
                value: 0
            },
            course: {},
            userInfo: {},
            headId: '',
            appraise: '',
            attitudeScore: '',
            abilityScore: '',
            defaultMemberName: '',
            fileList: [],
        }
    },
    methods: {
        goAllSourse() {
            this.$router.push('/allcourse')
        },
        goSet() {
            this.$router.push('/infoset')
        },
        goRank() {
            if(this.$help.storeTemp.get('personType') === 1){
                this.$router.push('/center/rank')
            }
            if(this.$help.storeTemp.get('personType') === 2){
                this.$router.push('/person/rank')
            }
        },
        goCharge() {
            this.$router.push('/center/charge')
        },
        goVoucher() {
            this.$router.push('/center/voucher')
        },
        goOrder(data) {
            this.$router.push({path: '/center/order', query: {
                type: data
            }})
        },
        goCourseOrder(data){
            this.$router.push({path: '/center/courseorder', query: {
                type: data
            }})
        },
        goIntegral() {
            this.$router.push('/center/integral')
        },
        goSingle() {
            this.$router.push({path: '/coach/singledetail', query: {
                coachId: this.userInfo.id
            }})
        },
        goMember(memberId, id) {
            this.$router.push({path: '/coach/memberdetail', query: {
                memberId,
                id
            }})
        },
        //人员 查看教练成单明细
        goCoachDetial(){
            this.$router.push({path: '/person/coachdetail', query: {
                corpId: this.userInfo.corpId
            }})
        },
        //人员 业绩
        goResults() {
            this.$router.push({path: '/person/results'})
        },
        goMemberDetail() {
            this.$router.push({path: '/person/memberlist', query: {
                personId: this.userInfo.id
            }})
        },
        //亲属
        goRelation() {
            this.$router.push({path: '/center/relation', query: {
                memberId: this.userInfo.id
            }})
        },
        //邀请
        goInvitation() {
            this.$router.push({path: '/center/invitation', query: {
                memberId: this.userInfo.id
            }})
        },

        beforeClose(action, done) {
            if (action === 'confirm') {
                if(!this.flag){
                    done(false)
                }else{
                    setTimeout(done, 100);
                }
            } else{
                done()
            }
        },
        changeRecode(){
            if(this.appraise === ''){
                this.$help.error("评语不能为空")
                this.flag = false
                return
            }
            if(this.attitudeScore === ''){
                this.$help.error("态度分值不能为空")
                this.flag = false
                return
            }
            if(this.abilityScore === ''){
                this.$help.error("能力分值不能为空")
                this.flag = false
                return
            }
            this.flag = true
            let form = {
                id: this.selectItem.id,
                abilityComment: this.appraise,
                attitudeScore: this.attitudeScore,
                abilityScore: this.abilityScore
            }
            this.$help.loading('评价中...')
            this.$api.post('member/bill/curriculumOrderAndTime/curriculumOrder-evaluation', form).then(res=>{
                if(res.data){
                    this.$help.success('评价成功')
                    this.getCourseList()
                }else{
                    this.$help.error(res.msg)
                }
                this.appraise = ''
                this.attitudeScore = ''
                this.abilityScore = ''
            })
            .finally(this.$help.loaded)
        },

        //教练评价
        changeEvaluate() {
            if(this.coachEvaluate.content === ''){
                this.$help.error("评语不能为空")
                this.flag = false
                return
            }
            let form = {
                id: this.selectItem.id,
                coachComment: this.coachEvaluate.content,
                rankScore: this.coachEvaluate.value
            }
            this.flag = true
            this.$help.loading('评价中...')
            this.$api.post('coach/bill/curriculumTime/curriculumOrder-comment', form).then(res=>{
                if(res.data){
                    this.$help.success('评价成功')
                    this.getCoachCourseList()
                }else{
                    this.$help.error(res.msg)
                }
                this.coachEvaluate.content = ''
                this.coachEvaluate.value = 0
            }).finally(this.$help.loaded)
        },  
        onCancelRecode(){
            this.appraise = ''
            this.attitudeScore = ''
            this.abilityScore = ''

            this.coachEvaluate.content = ''
            this.coachEvaluate.value = 0
        },
        goAppraise(data) {
            // console.log(data)
            this.selectItem = data
            // this.showAppraise = true
            if(this.$help.storeTemp.get('personType') === 0) {
                this.showAppraise = true
            }
            if(this.$help.storeTemp.get('personType') === 1) {
                this.coachEvaluate.content = data.coachComment || '';
                this.coachEvaluate.value = data.rankScore || 0;
                this.coachEvaluate.show = true
            }
        },
        //去上课
        goToCourse(data) {
            //console.log(data)
            if(this.$help.storeTemp.get('personType') === 1 && data.curriculumTimeStatus === 2) {
                this.course = data
                this.showCourse = true
                return
            }
        },
        //完成上课
        goComputed(data) {
            this.$help.confirm({
                title: '提示',
                message: '确定完成上课吗',
            })
            .then(() => {
                this.$api.post('coach/bill/curriculumTime/curriculumTime-status', {
                    id: data.id,
                    curriculumTimeStatus: 4
                }).then(res=>{
                    //console.log(res)
                    if(res.data) {
                        this.$help.success('处理成功!')
                        this.swiperList = []
                        this.swiperListed = []
                        this.getCoachCourseList()
                    }else{
                        this.$help.error(res.msg)
                    }
                })
            })
            .catch(() => {})
        },
        diaConfirm() {
            //console.log('去上课')
            this.$api.post('coach/bill/curriculumTime/curriculumTime-status', {
                id: this.course.id,
                curriculumTimeStatus: 3
            }).then(res=>{
                console.log(res)
                if(res.data) {
                    this.$help.success('处理成功!')
                    this.swiperList = []
                    this.swiperListed = []
                    this.getCoachCourseList()
                }else{
                    this.$help.error(res.msg)
                }
            })
        },
        diaCancel() {
            //console.log('已超时')
            this.$api.post('coach/bill/curriculumTime/curriculumTime-status', {
                id: this.course.id,
                curriculumTimeStatus: 5
            }).then(res=>{
                console.log(res)
                if(res.data) {
                    this.$help.success('处理成功!')
                    this.swiperList = []
                    this.swiperListed = []
                    this.getCoachCourseList()
                }else{
                    this.$help.error(res.msg)
                }
            })
        },
        singleConfirm() {
            this.$router.push({path: '/center/students/single', query:{
                headId: this.headId
            }})
        },
        singleCancel() {
            this.$router.push({path: '/center/students/close', query:{
                headId: this.headId
            }})
        },
        getMemberUser() { 
            this.$help.loading()
            this.$store.dispatch('getMemberUser').then(() => {
                this.userInfo = this.$store.state.memberUser
                if(this.$store.state.memberUser.memberName === this.defaultMemberName){
                    this.$help.confirm({
                        title: '提示',
                        message: '请完善个人信息',
                    })
                    .then(() => {
                        this.$router.push({path:'/center/realname'})
                    })
                    .catch(() => {})
                }
            })
            .finally(this.$help.loaded)
        },
        getCoachUser() { 
            this.$help.loading()
            this.$store.dispatch('getCoachUser').then(() => {
                this.userInfo = this.$store.state.coachUser
                this.bindOpenId()
            })
            .catch(this.$help.loaded)
        },
        getPersonUser() {
            this.$help.loading()
            this.$store.dispatch('getPersonUser').then(() => {
                this.userInfo = this.$store.state.personUser
                this.bindOpenId()
            })
            .catch(this.$help.loaded)
        },
        totalShopOrder() {
            // this.$help.loading()
            this.$api.post('member/bill/shoppingOrder/shoppingOrder-statistics').then(res=>{
                this.orderNum = res
            })
            // .finally(this.$help.loaded)
        },
        totalCourseOrder() {
            // this.$help.loading()
            this.$api.post('member/bill/curriculumOrderAndTime/curriculumOrder-statistics').then(res=>{
                this.courseOrderNum = res
            })
            // .finally(this.$help.loaded)
        },
        //人员课程
        getCourseList() {
            let model = {}
            this.swiperList = []
            this.swiperListed = []
            this.$set(model, 'curriculumTimeStatusStart', 2)
            this.$set(model, 'curriculumTimeStatusEnd', 6)
            let params = {
                page: {
                    current: 1,
                    pageSize: 20,
                    orders: [{column: "billDate", asc: false}]
                },
                model: model,
            } 
            this.$api.post('member/bill/curriculumOrderAndTime/curriculumTime-findCurriculumTimePage', params).then(res=>{
                //console.log(res)
                if(res.records && res.records.length !== 0) {
                    res.records.forEach(ele=>{
                        if(ele.curriculumTimeStatus === 2){
                            this.swiperList.push(ele)
                        }else{
                            this.swiperListed.push(ele)
                        }
                    })
                }
            })
        },
        //教练课程
        getCoachCourseList(){
            let model = {};
            this.swiperList = [];
            this.swiperListed = [];
            this.$set(model, 'curriculumTimeStatusStart', 2)
            this.$set(model, 'curriculumTimeStatusEnd', 6)
            let params = {
                page: {
                    current: 1,
                    pageSize: 20,
                    orders: [{column: "billDate", asc: false}]
                },
                model: model,
            } 
            this.$help.loading()
            this.$api.post('coach/bill/curriculumTime/curriculumTime-list', params).then(res=>{
                // console.log(res)
                if(res.records && res.records.length !== 0) {
                    res.records.forEach(ele=>{
                        if(ele.curriculumTimeStatus === 2){
                            this.swiperList.push(ele)
                        }else{
                            this.swiperListed.push(ele)
                        }
                    })
                }
            })
            .finally(this.$help.loaded)
        },

        //修改头像
        afterRead(file) {
            file.status = 'uploading'
            file.message = '上传中'
            let formData = new FormData();
            formData.append("file",file.file,file.file.name);

            if (this.type) {
                formData.append("fileType", this.type);
            }
            this.$help.loading('修改中...')
            this.$api.post('common/sys/attachment/attachmentUpload', formData, {'Content-Type': 'multipart/form-data'})
            .then(response => {
                this.$api.post(this.updateApi, {
                    id: this.userInfo.id,
                    picHeadUrl: response.id
                })
                .then(res => {
                    if(res.data) {
                        this.$help.success('修改成功')
                        this.userInfo.picHeadUrl = response.id
                    }else{
                        this.$help.error(res.msg)
                    }
                })
                // console.log(response.id)
            })
            .catch(() => {
                file.status = 'failed'
                file.message = '上传失败'
            })
            .finally(this.$help.loaded)
            
        },

        //退出登录
        exit(){
            this.$help.confirm({
                title: '提示',
                message: '确定退出吗',
            })
            .then(() => {
                this.$router.push({path:'/login'})
                this.$help.store.remove('COACH')
                this.$help.store.remove('PERSON')
            })
            .catch(() => {})
        },
        changeName() {
            this.$help.loading()
            this.$api.post('/sys/sysProp/sysProp-show',{id: 'defaultMemberName'}).then(res=>{
                //console.log(res)
                this.defaultMemberName = res.propertyValue
                this.getMemberUser()
            })
            .catch(this.$help.loaded)
        },

        onFollow() {
            this.$help.confirm({
                title: '提示',
                message: '确定关联微信吗',
            })
            .then(() => {
                this.$help.loading()
                let url = window.encodeURIComponent(`${window.location.origin}/${window.location.hash}`)
                let http = 'https://wechat.hongqihorse.com'
                if(window.location.hash.indexOf('?') !== -1){
                    url = window.encodeURIComponent(`${window.location.origin}/${window.location.hash.split('?')[0]}`)
                }
                window.location.href = `${http}/horse/wechat/wechatApi/getOpenId?returnUrl=${url}` 
            })
            .catch(() => {})
        },

        bindOpenId() {
            console.log(this.$route.query)
            let type = { 1: 'COACH', 2: 'PERSON' };
            if(this.$route.query && this.$route.query.code === '00000' && this.userInfo.mobile){
                this.$api.get('wechat/wechatApi/bindCoachOrPersonOpenId', {
                    mobile: this.userInfo.mobile, openId: this.$route.query.data,
                    userType: type[this.$help.storeTemp.get('personType')]
                })
                .then(res=>{
                    if(res.data) {
                        this.$help.success('关联成功')
                    }else{
                        this.$help.error('关联失败')
                    }
                })
                .finally(this.$help.loaded)
            }else {
                this.$help.loaded()
                // debugger
            } 
        }
    },
    created() {
      
        
    },
    mounted() {
        if(this.$help.storeTemp.get('personType') === 0) {
            //会员
            this.updateApi = 'member/base/member/member-update'
            this.personTypeName = '会员'
            this.orderName = '我的课程'
            this.totalShopOrder()
            this.totalCourseOrder()
            this.getCourseList()
            this.changeName()
        }
        if(this.$help.storeTemp.get('personType') === 1) {
            //
            this.updateApi = 'common/base/coach/coach-update'
            this.personTypeName = '教练'
            this.orderName = '上课信息'
            this.getCoachUser()
            this.getCoachCourseList()
            
        }
        if(this.$help.storeTemp.get('personType') === 2) {
            //
            this.updateApi = 'common/base/person/person-update'
            this.personTypeName = '会籍顾问'
            this.getPersonUser()
        } 
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .my-center {
        padding-bottom: env(safe-area-inset-bottom);
        padding-bottom: constant(safe-area-inset-bottom);
        &__header{
            padding: 15px 20px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .avatar{
                display: flex;
                flex-direction: row;
                // align-items: center;
                align-items: flex-end;
                .my-image{ 
                    width: 60px;
                    height: 60px;
                }
                .van-uploader__preview{
                    display: none;
                }
                .name{
                    padding-left: 10px;
                    font-weight: bold;
                    font-size: 18px;
                    padding-bottom: 5px;
                    sub{
                        padding-left: 5px;
                        color: $main;
                        font-weight: 500;
                    }
                }
                .follow{
                    padding-bottom: 5px;
                    margin-left: 10px;
                    .van-tag{
                        padding: 5px 10px;
                    }
                }
            }
            .code{
                padding: 5px 10px;
                border-radius: 19px;
                background-color: #fff;
                box-shadow: 0 0 9px 6px $boxShadow;
                color: $main;
                //font-weight: bold;
                font-size: 18px;
            }
        }
        .goods-order{
            margin: 15px 10px 10px;
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 0 9px 6px $boxShadow;
            .van-cell{
                padding: 5px 10px;
            }
            .order{
                display: flex;
                justify-content: space-between;
                padding: 0 50px 10px;
                i{
                    color: $main;
                    font-size: 20px;
                    padding-right: 5px;
                    //font-weight: bold;
                }
            }
        }

        &__course{
            margin: 10px 10px;
            display: flex;
            justify-content: space-between;
            border-radius: 10px;
            box-shadow: 0 0 9px 6px $boxShadow;
            background-color: #fff;
            .item{
                margin: 5px 0;
                //padding: 0 8px;
                display: flex;
                flex-direction: column;
                align-items: center;
                border-right: 1px solid #ededed;
                flex: 1;
                .type{
                    display: flex;
                    align-items: center;
                }
                i{
                    color: $main;
                    font-size: 20px;
                    padding-right: 5px;
                    //font-weight: bold;
                }
                .num{ 
                    color: $pink; 
                    font-weight: bold;
                }
                &:last-child{
                    border: none;
                }
            }
        }
        .order{
            font-size: 12px;
            .item{
                i{
                    padding-right: 2px;
                    font-size: 22px;
                }
                .type{
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    .my-badge{
                        position: absolute;
                        top: 0;
                        left: 18px;
                        //background-color: $main;
                    }
                }
            }
        }
        .my-cell{
            //background-color: unset;
            display: flex;
            align-items: center;
            .van-icon-arrow {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color:#DEEDFC;;
                text-align: center;
                line-height: 20px;
                color:#3992EA;
                margin-right:5px;
            }
            .van-cell__title{
                padding-left: 2px;
            }
        }
        .my-swipe{
            padding: 15px;
        }
        .finally{
            padding-bottom: 50px;
        }
        .my-popup{
            .code{
                height: 150px;
                width: 150px;
            }
        }
        .van-dialog{
            .dialogContent{
                text-align: center;
                padding: 20px 0;
            }
            .van-icon{
                position: absolute;
                top: 12px;
                right: 12px;
                color: $red;
                font-size: 20px;
            }
            .my-cell{
                .van-field__label{
                    width: 120px;
                }
            }
        }
        .center-btn{
            padding: 20px 30px;
            .van-button{
                border: 0;
                background-color: $main;
            }
        }
        .my-group {
            margin: 15px 0;
            .van-cell{
                padding:15px;
                .van-cell__right-icon{
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color:#DEEDFC;;
                    text-align: center;
                    line-height: 20px;
                    color:#3992EA;
                    margin-right:5px;
                }
                .van-cell__value{
                    padding-right: 10px;
                }
            }
            .blue {
                .van-cell__left-icon {
                    color: $main;
                }
            }
            .red {
                .van-cell__left-icon {
                    color: $red;
                }
            }
            .pink {
                .van-cell__left-icon {
                    color: $pink;
                }
            }
            .green {
                .van-cell__left-icon {
                    color: $green;
                }
            }
            .iconfont{
                padding-right: 5px;
            }
            .real-name{
                .van-cell__left-icon{
                    color: $yellow;
                }
            }  
            .qr{
                .van-cell__left-icon{
                    color: $main;
                }
            }
            .relation{
                .van-cell__left-icon{
                    color: $red;
                }
            }
        }
        .van-rate{
            width: 200px;
            height: 30px;
            &__item{
                width: 30px;
                height: 30px;
                .van-icon{
                    position: absolute;
                    top: 0;
                }
            }
        }
    }
</style>
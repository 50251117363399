<template>
    <div class="check-stand">
        <van-popup
            v-model="checkStand.show"
            closeable
            round
            position="bottom"
            class="my-popup"
            :style="{minHeight: '40%'}"
        >
            <div class="content">
                <div class="money">
                    ￥{{selectMoney}}
                </div>
                <van-cell-group>
                    <van-cell v-for="(item, i) in payType" :key="i" :title="item.name" >
                        <template #icon>
                            <van-image class="my-image" fit="fill" :src="imgWx" alt=""/>
                        </template>
                        <template #right-icon>
                            <van-icon name="arrow" />
                            <!-- <span class="balance">{{item.money ? '￥'+item.money : ''}}</span><van-radio :name="i" /> -->
                        </template>
                    </van-cell>
                    <slot></slot>
                </van-cell-group>
                <my-btn :btnStr="'确认付款'" @onSubmit="$emit('onSubmit')" />
            </div>
        </van-popup>
    </div>
</template>

<script>
export default {
    props: {
        checkStand: {
            type: Object,
            default: ()=>{
                return {}
            }
        },
        selectMoney: {
            type: String,
            default: ''
        },
        payType: {
            type: Array,
            default: ()=>{
                return []
            }
        }
    },
    data() {
        return {
            imgWx: require('@/assets/img/wx.png'),
        }
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .check-stand{
        .my-popup{
            .content{
                height: 100%;
               // 
                .money{
                    padding: 50px 20px 20px;
                    text-align: center;
                    font-size: 28px;
                    font-weight: bold;
                    
                }
                .van-radio-group {
                    padding-bottom: 64px;
                }
                .van-cell{
                    align-items: center;
                    &__title{
                        span{
                            padding-left: 10px;
                            font-size: 16px;
                        }
                    }
                    .balance{
                        padding: 0 10px;
                    }
                    .van-icon-paid{
                        color: $main;
                        padding-right: 5px;
                    }
                    .van-radio__icon--checked{
                        .van-icon{
                            color: #fff;
                            background-color: $main;
                            border-color: $main;
                        }
                    }
                }
            }
        }
    }

</style>
<style lang="scss">

    @import '~@/assets/css/var';
    .img_upload{
        .my-upload {
            flex: 1;
            margin-bottom: 20px;
            vertical-align: top;
            padding: 10px 0 0 10px;
            .van-uploader__wrapper { 
                justify-content: start;
                .van-uploader__preview{
                    margin-right:10px;
                    margin-bottom: 10px;
                }
            }
            .van-uploader__upload{
                border: 1px dotted #ccc;
            }
            .van-uploader__upload,  .van-uploader__preview-image, .van-uploader__file {
                width: 100px;
                height: 100px;
                //margin-bottom:10px;
            }
        }
    }

</style>
<template>
    <div class="img_upload">
        <van-uploader class="my-upload" v-model="files" :after-read="afterRead" :accept="accept" :upload-text="desc" @delete="deleteImg" deletable>
            <slot></slot>
        </van-uploader>
    </div>
</template>

<script>
    export default {
        props: {
            fileList:{
                type:Array,
                default() {
                    return []
                }
            },
            desc: {
                type: String,
                default: ''
            },
            accept: {
                type: String,
                default: 'image/*'
            }
        },
        data() {
            return {
                files: [],
                pictureList: [],
            }
        },
        created() {
            this.files = this.fileList
        },
        methods: {
            afterRead(file) {
                file.status = 'uploading'
                file.message = '上传中'
                let formData = new FormData();
                formData.append("file",file.file,file.file.name);

                if (this.type) {
                    formData.append("fileType", this.type);
                }
                this.$api.post('sysAttachment', formData, {'Content-Type': 'multipart/form-data'})
                    .then(res => {
                        this.pictureList.push(res.id)
                        file.status = '';
                        //console.log(this.handleFile(this.pictureList))
                        this.$emit("getImage", this.handleFile(this.pictureList))
                    })
                    .catch(() => {
                        file.status = 'failed'
                        file.message = '上传失败'
                    })
                
            },
            deleteImg(file, index) {
                this.pictureList.splice(index.index, 1)
                //console.log(this.handleFile(this.pictureList))
                this.$emit("getImage", this.handleFile(this.pictureList))
            },

            handleFile(arr){
                return arr.toString()
            },

            setValue(){
                this.files = []
                //console.log(value)
            }
           
        },
        mounted() {
           
        }
    }
</script>
<template>
    <div class="my-header">
        <div class="visit_list">
            <div class="list"><van-icon name="bars" /><span>列表</span></div>
            <div class="screen" @click="$emit('screening')" ><span>筛选</span><van-icon name="filter-o" /></div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .my-header{
        height: 20px;
        .visit_list{ 
            height: 44px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: fixed;
            top:0;
            left:0;  
            z-index: 999;  
            .list{
                width: 35%;
                text-align: center;
                background-color: #fff;
                color: $main;
                line-height: 44px;
                border-radius: 0 28px 28px 0;
                box-shadow: 0 0 9px 3px $boxShadow;
                span{
                    padding-left: 5px;
                }
            }
            .screen{
                width: 35%;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 44px;
                background-color: $main;
                
                color:#fff;
                border-radius: 28px 0 0 28px;
                box-shadow: 0 0 9px 3px $boxShadow;
                span{
                    padding-right:5px;
                }
            }
            .iconfont{
               font-size: 14px;
            }
        }
    }
</style>
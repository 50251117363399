<template>
    <div class="my-voucher" >
        <div class="voucher" @click="$emit('goDetail')">
            <div class="voucher-avater">
                <van-image class="my-image" fit="cover" round :src="getImg(item.picUrl)" alt="">
                    <template v-slot:error><van-image class="my-image" fit="cover" round :src="imgCard" alt=""/></template>
                </van-image>
                <span>{{item.cardName}}</span>
            </div>
            <div class="info horse">
                <div class="label">所属校区</div>
                <div class="value">{{item.corpName}}</div>
            </div>
            <div class="info" v-if="item.permanentFlag === 1">
                <div class="label">有效期限</div>
                <div class="value">永久有效</div>
            </div>
            <div class="info" v-if="item.permanentFlag === 0">
                <div class="label">有效期限</div>
                <div class="value">{{item.validityDuration}}个月</div>
            </div>
            <div class="info">
                <div class="label">课程名称</div>
                <div class="value">{{item.curriculumName | courseName}}</div>
            </div>
            <slot name="content"></slot>
            <div class="bottom" v-if="bool">
                <slot name="bottom"></slot>
            </div>
            <div class="circle left-top"></div>
            <div class="circle left-bottom" v-if="bool"></div>
            <div class="circle right-top"></div>
            <div class="circle right-bottom" v-if="bool"></div>

            <div class="tip-status" v-if="showTip === 0" >
                待付款
            </div>
            <div class="tip-status" v-if="showTip === 1" >
                已发起
            </div>
            <div class="tip-status-success" v-if="showTip === 2" >
                已付款
            </div>
            <div class="tip-status" v-if="showTip === 8" >
                已退订
            </div>
            <div class="tip-status" v-if="showTip === 9" >
                已退款
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        item: {
            type: Object,
            default: () => {
                return {}
            }
        },
        showTip: {
            type: Number,
            default: null
        },
        bool: {
            type:Boolean,
            default: true
        }
    },
    data(){
        return {
            imgCard: require('@/assets/img/card.jpeg'),
        }
    },
    filters: {
        courseName(val) {
            let str = val
            let arr = []
            if(str) {
                if(str.indexOf('*##*') !== -1){
                    str.split(',').forEach(ele=>{
                        arr.push(ele.split('*##*')[1])
                    })
                    str = arr.join()
                }
            }
            return str
        }
    },
    methods:{
    
    },
    mounted() {
        //console.log(this.item)
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var.scss';
    .my-voucher{
        margin-bottom: 20px;
        .voucher{
            box-shadow: 0 0 8px 6px $boxShadow;
            border-radius: 9px;
            padding: 10px;
            position: relative;
            overflow: hidden;
            background-color: #fff;
            &-avater{
                display: flex;
                align-items: center;
                padding: 0 10px 11px;
                .van-image__error{
                    border-radius: 50%;
                } 
                span{
                    padding-left: 5px;
                }
                .my-image{
                    width: 45px;
                    height: 45px;
                    border-radius: 50%;
                    border: 1px solid $gray;
                    img{
                        border-radius: 50%;
                    }
                   
                }
            }
            &-code{
                color: $gray;
            }
            .horse{
                padding-top: 10px;
                border-top: 1px dashed $gray;
            }
            .last{
                padding-bottom: 10px;
            }
            .info{
                display: flex;
                justify-content: space-between;
                line-height: 30px;
                padding-left: 10px;
                padding-right: 10px;
                .label{
                    color: $gray;
                }
            } 
            .color{
                .value{
                    color: $main;
                }
            }   
            .money{
                .value{
                    color: $red;
                }
            }
            .bottom{
                padding-top: 9px;
                border-top: 1px dashed $gray;
                .btn{
                    display: flex;
                    justify-content: flex-end;
                    .van-button{
                        background-color: $main;
                        border: 0;
                        padding: 0 30px;
                        color: #fff;
                        height: 30px;
                        margin-right: 5px;
                    }
                }
                .tip{
                    padding: 5px 10px 5px 10px;
                    text-align: center;
                    color: $pink;
                    min-width: 100px;
                    max-width: 180px;
                }
            }
            .circle{
                height: 20px;
                width: 20px;
                position: absolute;
                border-radius: 50%;
                background-color: $boxShadow;
            }
            .left-top{
                top: 57px;
                left: -10px;
            }
            .left-bottom{
                bottom: 40px;
                left: -10px;
            }
            .right-top{
                top: 57px;
                right: -10px;
            }
            .right-bottom{
                bottom: 40px;
                right: -10px;
            }
            .tip-status, .tip-status-success{
                width: 50px;
                border-radius: 50%;
                border: 1px solid $red;
                position: absolute;
                top: -8px;
                right: -8px;
                line-height: 50px;
                text-align: center;
                color: $red;
                transform: rotate(45deg);
                
            }
            .tip-status-success{
                border: 1px solid $green !important;
                color: $green !important;
            }
        }
    }
</style>
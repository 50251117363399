<template>
    <div class="my-course">
        <van-sticky>
            <div class="my-course__search">
                <van-search
                    class="my-search"
                    v-model="value"
                    shape="round"
                    placeholder="输入马场或课程套餐名称"
                    @search="onSearch"
                    >
                </van-search>
            </div>
        </van-sticky>
        <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                @load="onLoad1"
                class="my-list"
                >
                <div class="my-course-list">
                    <my-voucher v-for="item in list" :key="item.id" :item="item">
                        <div slot="content">
                            <div class="info money">
                                <div class="label">消费价格</div>
                                <div class="value">￥{{item.priceTotal || 0}}</div>
                            </div>
                            <div class="info money last">
                                <div class="label">有效鞍时</div>
                                <div class="value">{{item.cardHours}}鞍时</div>
                            </div>
                        </div>
                        <div class="btn" slot="bottom">
                            <van-button round @click.stop="goShopping(item)" :disabled="$help.storeTemp.get('personType') !== 0">购买</van-button>
                        </div>        
                    </my-voucher>
                </div>
                <no-more v-show="showNoMore && !loading"/>
            </van-list>
            <no-data v-show="showData"/>
        </van-pull-refresh>
        <van-popup class="my-popup" v-model="showGoods">
            <div class="Goods">
                <div class="Goods-info">
                    <div class="Goods-info__img">
                        <van-card
                            :price="totals"
                            :title="cardName"
                            :thumb="imgCard"
                        >
                            <template #num>
                                <van-stepper v-model="num" step="1" @change="changeNum"/>
                            </template>
                        </van-card>
                    </div>
                </div>
                
                <div class="integral">
                    <div class="int"><span>总共积分</span><span class="color">({{integralTotal}})</span></div>
                    <div class="int"><span>推荐使用积分</span><input class="input" type="number" ref="input" v-model="integral" @input="onChangeInput"/></div>
                    <van-checkbox v-model="checked" @change="onChangeInput"></van-checkbox>
                </div>

                <div class="integral">
                    <div class="int"><span>余额</span><span class="color">({{balanceTotal}})</span></div>
                    <div class="int"><span>使用余额</span><input class="input" type="number" ref="inputs" v-model="balance" @input="onChageInputBa"/></div>
                    <van-checkbox v-model="checkedBalance" @change="onChageInputBa"></van-checkbox>
                </div>

                <div class="btn">
                    <div class="car" @click="onSubmit">立即支付￥{{settlement}}</div>
                    <div class="close" @click="showGoods = false">关闭</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import mixinPage from '@/utils/mixinPage';
export default {
    mixins: [ mixinPage ],
    data() {
        return {
            api: 'common/base/card/card-list',
            imgCard: require('@/assets/img/card.jpeg'),
            action: 1,
            value: '',
            showGoods: false,
            num: 1,
            cardName: '',
            price: 0,
            checked: false,
            integralTotal: 0,
            //积分换算
            conversion: 0,
            //金额换算
            conversionBa: 1,
            integral: 0,
            checkedBalance: false,
            balanceTotal: 0,
            balance: 0,
            model: {},
            cardId: '',
            orders: [{column: "priceTotal", asc: true}]
        }
    },
    computed: {
        totals() {
            let totals = (this.price * this.num).toFixed(2)
            return totals
        },
        settlement() {
            if(this.checked && this.checkedBalance){
                let settlement = (this.totals - this.integral/this.conversion - this.balance).toFixed(2)
                if(settlement<0){
                    return 0.00
                }else{
                    return settlement
                }
            }
            if(this.checkedBalance){
                let settlement = (this.totals - this.balance).toFixed(2)
                if(settlement<0){
                    return 0.00
                }else{
                    return settlement
                }
            }
            if(this.checked){
                let settlement = (this.totals - this.integral/this.conversion).toFixed(2)
                if(settlement<0){
                    return 0.00
                }else{
                    return settlement
                }
            }else{
                return this.totals
            }
        }
    },
    methods: {
        init() {
            this.initList()
        },
        onSearch() {
            this.$set(this.model, 'keyword', this.value)
            this.init()
        },
        goShopping(data) {
            this.num = 1
            this.price = data.priceTotal
            this.cardId = data.id
            this.cardName = data.cardName
            if(data.picUrl){
                this.imgCard = this.getImg(data.picUrl)
            }else{
                this.imgCard = require('@/assets/img/card.jpeg')
            }
            this.showGoods = true
            this.checked = false
            this.checkedBalance = false
            if((this.totals*this.conversion-0) > this.integralTotal){
                this.integral =  (this.integralTotal-0).toFixed(0)
            }else{
                this.integral = (this.totals*this.conversion-0).toFixed(0)
            }
        },
        onChangeInput() {
            //判断积分够不够 够的话积分不能超过物品价格
            if(this.checkedBalance){
                //如果余额勾选了
                if(this.integralTotal > this.totals*this.conversion){
                    //够
                    if((this.$refs.input.value-0) >= (this.totals-(this.balance-0))*this.conversion){
                        this.$refs.input.value = (this.totals-(this.balance-0))*this.conversion
                        this.integral = (this.totals-(this.balance-0))*this.conversion
                    }else{
                        this.integral = this.$refs.input.value 
                    }
                }else{
                    //不够
                    if((this.$refs.input.value-0) >= this.integralTotal){
                        this.$refs.input.value = this.integralTotal
                        this.integral = this.integralTotal
                    }else{
                        this.integral = this.$refs.input.value 
                    }
                }

            }else{
                if(this.integralTotal > this.totals*this.conversion){
                    //够
                    if((this.$refs.input.value-0) >= this.totals*this.conversion){
                        this.$refs.input.value = this.totals*this.conversion
                        this.integral = this.totals*this.conversion
                    }else{
                        this.integral = this.$refs.input.value 
                    }
                }else{
                    //不够
                    if((this.$refs.input.value-0) >= this.integralTotal){
                        this.$refs.input.value = this.integralTotal
                        this.integral = this.integralTotal
                    }else{
                        this.integral = this.$refs.input.value 
                    }
                }
            }
            
        },
        onChageInputBa() {
            // //先判断是否勾选了积分
            if(this.checked){
                //如果余额勾选了
                if(this.balanceTotal > this.totals*this.conversionBa){
                    //够
                    if((this.$refs.inputs.value-0) >= (this.totals-(this.integral/this.conversion))*this.conversionBa){
                        this.$refs.inputs.value = (this.totals-(this.integral/this.conversion))*this.conversionBa
                        this.balance = (this.totals-(this.integral/this.conversion))*this.conversionBa
                    }else{
                        this.balance = this.$refs.inputs.value 
                    }
                }else{
                    //不够
                    if((this.$refs.inputs.value-0) >= this.balanceTotal){
                        this.$refs.inputs.value = this.balanceTotal
                        this.balance = this.balanceTotal
                    }else{
                        this.balance = this.$refs.inputs.value 
                    }
                }

            }else{
                if(this.balanceTotal > this.totals*this.conversionBa){
                    //够
                    if((this.$refs.inputs.value-0) >= this.totals*this.conversionBa){
                        this.$refs.inputs.value = this.totals*this.conversionBa
                        this.balance = this.totals*this.conversionBa
                    }else{
                        this.balance = this.$refs.inputs.value 
                    }
                }else{
                    //不够
                    if((this.$refs.inputs.value-0) >= this.balanceTotal){
                        this.$refs.inputs.value = this.balanceTotal
                        this.balance = this.balanceTotal
                    }else{
                        this.balance = this.$refs.inputs.value 
                    }
                }
            }
        },
        changeNum() {
            let integral = this.totals*this.conversion
            if( integral >= this.integralTotal){
                if(this.checkedBalance){
                    this.integral = (this.integralTotal - this.balance/this.conversionBa*this.conversion).toFixed(0)
                }else{
                    this.integral = (this.integralTotal-0).toFixed(0)
                }
            }else{
                if(this.checkedBalance){
                    this.integral = (integral - this.balance/this.conversionBa*this.conversion).toFixed(0)
                }else{
                    this.integral = (integral-0).toFixed(0)
                }
            }
        },
        onSubmit() {
            //结算时 传入 积分和优惠后的价格
            //积分
            //console.log(this.integral)
            //价格
            //console.log(this.settlement-0)
            let form = {}
            if(this.checkedBalance){
                this.$set(form, 'balanceAmount', this.balance)
            }
            if(this.checked){
                this.$set(form, 'integral', this.integral)
            }
            this.$set(form, 'cardId', this.cardId)
            this.$set(form, 'quantity', this.num)
            this.$set(form, 'payAmount', (this.settlement-0))
            console.log(form)
            this.$help.loading()
            this.$api.post('member/bill/cardOrder/cardOrder-save', form).then(res=>{
                //console.log(res)
                let url = window.encodeURIComponent(`${window.location.origin}/#/center/pay?orderId=${res.id}&orderType=${res.orderPayInfoVO.orderType}`)
                window.location.href = `${res.orderPayInfoVO.jumpGetOpenIdUrl}?returnUrl=${url}`
            })
            .finally(this.$help.loaded)
        },
        getMemberUser() { 
            this.$store.dispatch('getMemberUser').then(() => {
                this.integralTotal = this.$store.state.memberUser.integral
                this.balanceTotal = this.$store.state.memberUser.amount
            })
        },
        
        //积分兑换率
        getRate() {
            this.$api.post('sys/sysProp/sysProp-show', {id: '000003'}).then(res=>{
                this.conversion = res.propertyValue
            })
        },
        goDetail(id) {
            // console.log(id)
            this.$router.push({path: '/course/detail', query: {id}})
        }
        
    },
    created() {
        this.getRate()
    },
    mounted() {
        // this.$set(this.model, 'status', 1)
        this.init()
        if(this.$help.storeTemp.get('personType') === 0) {
            //会员
            this.getMemberUser()
        }
    }
    
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .my-course{
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
        .my-pull-refresh{
            margin-top: 20px;
            padding: 0 20px
        }
        &__search {
            // margin: 40px 0 20px;
            padding: 0 20px 0 8px;
            background-color: #fff;
            .van-search__action{
                color: $main;
            }
        }
        // &-list{
        //     padding: 20px 20px 0;
        // }
        .my-popup{
            border-radius: 19px;
            .Goods {
                min-height: 45vh;
                width: 300px;
                border-radius: 19px;
                &-info{
                    margin-top: 40px;
                    margin-bottom: 10px;
                    &__img{
                        border-radius: 19px;
                        img{
                            border-radius: 19px;
                        }
                    }
                }
                .integral{
                    padding: 10px 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top: 1px solid #ededed;
                    border-bottom: 1px solid #ededed;
                    .int{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        min-width: 60px;
                        .color{
                            color: $pink;
                        }

                    }
                    .input{
                        border: 0;
                        color: $pink;
                        outline: none;
                        border-bottom: 1px solid $pink;
                        width: 80px;
                        text-align: center;
                    }
                    .van-checkbox__icon--checked .van-icon{
                        background-color: $main;
                        border-color: $main;
                    }
                    // span{
                    //     color: $pink;
                    // }
                }
            }
            .btn{
                margin-top: 20px;
                padding: 0 15px;
                .car{
                    background-color: $yellow;
                    border-radius: 9px;
                    text-align: center;
                    padding: 10px 0;
                    font-size: 16px;
                    //color: #fff;
                    //font-weight: bold;
                }
                .close{
                    text-align: center;
                    margin: 20px 0;
                    //margin-top: 20px;
                }
            }
            
        }
    }
</style>
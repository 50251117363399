import api from './api'
import help from '@/utils/help'
export default {
    state: {
        memberUser: {},
        coachUser: {},
        personUser: {},
        active: 0,
        //0会员  1教练  2顾问
        personType: null,
        personTypeName: ''
    },
    mutations: {
        commitData(state, data){
            state.active = data
        },
        setPersonType(state, data){
            state.personType = data
            if(data === 0){
                state.personTypeName = '会员'
            }  
            if(data === 1){
                state.personTypeName = '教练'
            }  
            if(data === 3){
                state.personTypeName = '人员'
            }    
        }
    
    },
    actions: {
        getMemberUser({state}) {
            if(help.storeTemp.get('personType') === 0){
                return api.get('member/base/member/member-login-info')
                .then(res => {
                    state.memberUser = res
                    return res
                })
            }
            
        },
        getCoachUser({state}) {
            if(help.storeTemp.get('personType') === 1){
                return api.post('coach/base/coach/coach-showForCoach')
                .then(res => {
                    state.coachUser = res
                    return res
                })
            }
        },
        getPersonUser({state}) {
            if(help.storeTemp.get('personType') === 2){
                return api.post('common/base/person/person-show')
                .then(res => {
                    state.personUser = res
                    return res
                })
            }
        },

    }
}
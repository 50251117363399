import axios from 'axios'
import variable from '@/utils/variable'
import help from '@/utils/help'
import main from '@/main'

let myAxios = axios.create({
    baseURL: '/',
    responseType: 'json',
    withCredentials: true,
    timeout: 20000,
    headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': "application/json;charset=UTF-8",
        //'businessId': '03'
    },
})

myAxios.interceptors.request.use(function (config) {
    //console.log(help.storeTemp.get('personType'))
    if(help.storeTemp.get('personType') === 0 && help.token.get()){
        config.headers['Authorization'] = help.token.get()
    }
    if(help.storeTemp.get('personType') === 1 && help.store.get('COACH')){
        config.headers['Authorization'] = help.store.get('COACH')
    }
    if(help.storeTemp.get('personType') === 2 && help.store.get('PERSON')){
        config.headers['Authorization'] = help.store.get('PERSON')
    }
    //config.headers['Authorization'] = token
    return config;
}, function (error) {
    return Promise.reject(error);
})


function doError(res, reject, isBubbling) {
    // console.log(res)
    // debugger
    if (res && res.status) {
        if (variable.tokenInvalid.includes(res.status)) {
            //help.error(res.msg)
            if(help.storeTemp.get('personType') === 0 || !help.storeTemp.get('personType')){
                // if(window.location.hash.length < 50){
                    let url = window.encodeURIComponent(`${window.location.origin}/${window.location.hash}`)
                    window.location.href = `http://wechat.hongqihorse.com/horse/wechat/wechatApi/register?returnUrl=${url}&personType=MEMBER`
                // }
            }else if(window.location.hash === '#/login'){
                !isBubbling && help.error(res.msg);
            }else {
                main.$router.push('/login')
            }
        } else if (res.status === 'C0110') {
            !isBubbling && help.error('系统繁忙，请稍后重试')
        } else {
            //console.log(res.message)
            !isBubbling && help.error(res.msg);
        }
    } else {
        !isBubbling && help.error('服务器内部错误')
    }

    reject(res.data)
}

function requestFunc(func, url, params, headers = {}, isBubbling = false) {
    return new Promise((resolve, reject) => {
        let param1 = {},
            param2 = {};
        if (['get', 'delete'].includes(func)) {
            param1 = {params, headers};
        } else {
            param1 = params;
            param2 = {headers}
        }
        //debugger
        myAxios[func](`horse/${url}`, param1, param2)
            .then(res => {
                // console.log(res)
                if (res.data.status === variable.successCode) {
                    if (typeof res.data.data === 'object') {
                        res.data.data.rootData = res.data;
                        resolve(res.data.data)
                    } else {
                        resolve(res.data)
                    }
                } else {
                    doError(res.data, reject, isBubbling)
                }
            })
            .catch(res => {
                // debugger
                doError(res, reject, isBubbling)
            })
    })
}

export default {
    service: myAxios,
    axios,
    get(url, params, headers) {
        return requestFunc('get', url, params, headers)
    },
    post(url, params = {}, headers = {}) {
        return requestFunc('post', url, params, headers)
    },
    put(url, params = {}, headers = {}) {
        return requestFunc('put', url, params, headers)
    },
    patch(url, params = {}, headers = {}) {
        return requestFunc('patch', url, params, headers)
    },
    delete(url, params = {}, headers = {}) {
        return requestFunc('delete', url, params, headers)
    },
}
<template>
    <div class="my-item" @click="$emit('goDedail')">
        <div class="my-item__img">
                <van-image class="my-image" fit="cover" :src="getImg(picUrl)" alt="">
                    <template v-slot:error><van-image class="my-image" fit="cover" :src="imgUrl1" alt=""/></template>
                </van-image>
            <!-- <i class="iconfont icon-xiazai"></i> -->
        </div>
        <slot name="info">

        </slot>
        <slot name="appoitment">

        </slot>
    </div>
</template>

<script>
export default {
    props:{
        picUrl: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            imgUrl1: require('@/assets/img/horse1.jpg'),
        }
    },
    methods:{
    
    },
    mounted() {
        //console.log(this.item)
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var.scss';
    .my-item{
        margin-bottom: 30px;
        box-shadow: 0 0 9px 6px $boxShadow;
        border-radius: 19px;
        display: flex;
        position: relative;
        height: 130px;
        background-color: #fff;
        .my-item__img{
            flex: 5;
            position: relative;
            .iconfont{
                position: absolute;
                top: 10px;
                left: 10px;
                color: #fff;
                font-size: 20px;
            }
            .my-image{
                width: 100%;
                height: 100%;
                img{
                    border-radius: 19px;
                }
            
            }
        }
        .info{
            flex: 3;
            font-size: 12px;
            padding: 0px 5px 10px 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .horse{
                font-size: 12px;
                color: $pink;
                display:-webkit-box;/**对象作为伸缩盒子模型展示**/
                -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
                -webkit-line-clamp: 2;/**显示的行数**/
                overflow:hidden;/** 隐藏超出的内容**/
            }
            .inv{
                font-size: 12px;
                color: $pink;
                display:-webkit-box;/**对象作为伸缩盒子模型展示**/
                -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
                -webkit-line-clamp: 1;/**显示的行数**/
                overflow:hidden;/** 隐藏超出的内容**/
            }
            .address{
                font-size: 12px;
                display:-webkit-box;/**对象作为伸缩盒子模型展示**/
                -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
                -webkit-line-clamp:1;/**显示的行数**/
                overflow:hidden;/**隐藏超出的内容**/
                color: $gray;
                i{
                    color: $main;
                }
            }
        }
        .money{
            // position: absolute;
            // padding: 8px 30px 8px 15px;
            // top: 15px;
            left: 164px !important;
            // background-color: $main;
            // color: #fff;
            // border-radius: 9px;
            // font-size: 16px;
        }
        
        .appoitment{
            position: absolute;
            top: 15px;
            left: 175px;
            border-radius: 9px;
            .van-button{
                background-color: $main;
                padding-right: 30px;
                color: #fff;
                border: 0;
                border-radius: 9px; 
                &__text{
                    font-size: 16px;
                }
            }
            .icon {
                background-color: $yellow;
                color: $main;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 5px;
                right: -6px;
                padding: 3px;
                border-radius: 5px;
                width: 15px;
                height: 15px;
                span{
                    font-size: 12px;
                }
            }
        }
        .disabled{
            background-color: $gray;
            // .icon {
            //     background-color: $gray;
            //     color: $main;
            //     position: absolute;
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     top: 5px;
            //     right: -6px;
            //     padding: 3px;
            //     border-radius: 5px;
            //     width: 15px;
            //     height: 15px;
            //     span{
            //         font-size: 12px;
            //     }
            // }
        }
    }
</style>
<style lang="scss">
    @import '~@/assets/css/var';
    .my-upload {
        flex: 1;
        margin-bottom: 20px;
        .van-uploader__wrapper {
            justify-content: center;
        }
        .van-uploader__upload, .van-uploader__preview, .van-uploader__preview-image {
            width: 100px;
            margin: 0;
            height: 100px;
        }

    }
</style>
<template>
    <van-uploader class="my-upload" v-model="files" :max-count="1" :after-read="afterRead" :upload-text="desc" :disabled="read" :deletable="!read" />
</template>

<script>
    export default {
        props: {
            desc: {},
            value: {},
            read: {
                type: Boolean,
                default: false,
            },
            type: {}
        },
        data() {
            return {
                files: [],
                model: '',
            }
        },
        methods: {
            afterRead(file) {
                file.status = 'uploading'
                file.message = '上传中'
                let formData = new FormData();
                formData.append("file",file.file,file.file.name);

                if (this.type) {
                    formData.append("fileType", this.type);
                }
                this.$api.post('sysAttachment', formData, {'Content-Type': 'multipart/form-data'})
                    .then(res => {
                        //console.log(res)
                        this.model = res.id;
                        file.status = '';
                        this.$emit("getImg",this.model)
                    })
                    .catch(() => {
                        file.status = 'failed',
                        file.message = '上传失败';
                    })
                
            },
        }
    }
</script>
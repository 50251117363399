<template>
    <div class="my-mall">
        <!-- <div class="my-mall__header">
            <div class="shopping">
                <span>购物车</span>
                <van-badge class="my-badge" :content="7" max="99"/>
            </div>
            <div class="settlement" @click="goShopCar">
                结算支付
            </div>
        </div> -->
        <van-sticky>
            <div class="my-mall__search">
                <van-search
                    class="my-search"
                    v-model="value"
                    shape="round"
                    placeholder="输入马场或商品名称"
                    @search="onSearch"
                    >
                </van-search>
            </div>
        </van-sticky>

        <div class="content">
            <div class="left" v-if="levelList.length !== 0">
                <div class="line" v-for="(item, i) in levelList" :key="i" :class="{'action': action === i}" @click="changeAction(i, item)">
                    {{item.itemText}}
                </div>
            </div>
            <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
                <van-list v-model="loading" :finished="finished"  @load="onLoad1" class="my-list">
                    <my-item v-for="item in list" :key="item.id" @goDedail="goDedail(item.id)" :pic-url="item.picUrl">
                        <div class="info" slot="info">
                            <div class="inv">{{item.invName}}</div>
                            <div class="address">{{way[item.logisticsFlag]}}</div>
                            <div class="address">供货商: {{item.supplier}}</div>
                        </div>
                        <div class="appoitment money" slot="appoitment" @click.stop="addShop(item)">
                            <van-button :disabled="$help.storeTemp.get('personType') !== 0">{{item.price}}元</van-button>
                            <div class="icon">
                                <span>购</span>
                            </div>
                        </div>
                    </my-item>
                    <no-more v-show="showNoMore && !loading"/>
                </van-list>
            </van-pull-refresh>
        </div>
        <!-- 暂无数据 -->
        <no-data v-if="showData"/>
        <van-popup class="my-popup" v-model="showGoods">
            <div class="goods">
                <!-- <div class="title">
                    {{goods.title}}
                </div> -->
                <div class="goods-info">
                    <div class="goods-info__img">
                        <!-- <van-image class="my-image" fit="fill" :src="imgUrl1" alt=""/> -->
                        <van-image class="my-image" fit="cover" :src="picUrl" alt="">
                            <template v-slot:error><van-image class="my-image" fit="fill" :src="imgUrl1" alt=""/></template>
                        </van-image>
                    </div>
                    <div class="goods-info__desc">
                        <div class="money">
                            {{goods.price}}元 /<span>{{goods.invUnit}}</span>
                        </div>
                        <div class="desc">
                            <div>{{goods.name}}</div>
                        </div>
                    </div>
                </div>
                <div class="total">
                    <div class="total-money">小计: ￥{{subtotal}}</div>
                    <van-stepper v-model="num" step="1" min="1"  @change="changeNum"/>
                </div>

                <div class="integral">
                    <div class="int"><span>余额</span><span class="color">({{balanceTotal}})</span></div>
                    <div class="int"><span>使用余额</span><input class="input" type="number" ref="inputs" v-model="balance" @input="onChageInputBa"/></div>
                    <van-checkbox v-model="checkedBalance" @change="onChageInputBa"></van-checkbox>
                </div>

                <van-cell-group v-if="goods.way === 1">
                    <van-field class="my-filed" v-model="mobile" required label="手机号" placeholder="请输入手机号" />
                    <van-field class="my-filed" v-model="address" required label="收货地址" placeholder="请输入收货地址" />
                </van-cell-group>
                
                <div class="btn">
                    <div class="car" @click="addCar">立即支付￥{{realSubTotal}}</div>
                    <div class="close" @click="showGoods = false">关闭</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import mixinPage from '@/utils/mixinPage';
export default {
    mixins: [ mixinPage ],
    data() {
        return {
            api: 'common/base/inv/inv-list',
            imgUrl1: require('@/assets/img/horse1.jpg'),
            picUrl: '',
            action: 0,
            value: '',
            quantity: 0,
            showGoods: false,
            num: 1,
            goods: {
                price: 0,
                name: '',
                invUnit: '',
                title: '',
                way: ''
            },
            way: ['自提','邮寄'],
            invId: '',
            //金额换算
            conversionBa: 1,
            checkedBalance: false,
            balanceTotal: 0,
            balance: 0,
            model: {},
            levelList: [],
            mobile: '',
            address: ''

        }
    },
    computed: {
        subtotal() {
            let total = 0;
            total = (this.goods.price-0) * this.num
            return total.toFixed(2)
        },
        realSubTotal(){
            if(this.checkedBalance){
                return (this.subtotal - this.balance*this.conversionBa).toFixed(2)
            }else{
                return this.subtotal
            }
        }
    },
    methods: {
        init() {
            this.initList()
        },
        goDedail(id) {
            this.$router.push({path: '/mall/detail', query: {
                id: id
            }})
        },
        getLevel() {
            //debugger
            this.$api.post('sys/sysList/sysList-list-byName', {name: '物品分类'}).then(res=>{
                //console.log(res)
                if(res.length !== 0){
                    res.forEach(ele=>{
                        this.levelList.push({
                            itemText: ele.itemText,
                            itemValue: ele.itemValue
                        })
                    })
                }
            })
        },
        onSearch() {
            this.$set(this.model, 'keyword', this.value)
            this.init()
        },
        changeAction(i, data) {
            this.action = i
            this.goods.title = data.itemText
            this.$set(this.model, 'invType', data.itemValue)
            this.init()
        },
        //打开购物弹框
        addShop(data) {
            if(this.$help.storeTemp.get('personType') === 0) {
                //会员
                this.showGoods = true
                this.num = 1
            }
            this.goods.price = data.price
            this.goods.way = data.logisticsFlag
            this.picUrl = this.getImg(data.picUrl)
            if(this.balanceTotal > this.subtotal*this.conversionBa){
                //debugger
                this.balance = this.subtotal*this.conversionBa
            }else{
                this.balance = this.balanceTotal
            }
            this.invId = data.id
            this.goods.name = data.invName
            this.goods.invUnit = data.invUnit
            //this.getInventory(data.corpId, data.id)
        },
        getInventory(corpId, invId) {
            this.$api.post('bill/warehouseReport/warehouseReport-list', {corpId, invId}).then(res=>{
                console.log(res)
                if(res.records.length !== 0){
                    this.quantity = res.records[0].quantity
                }
            })
        },
        //立即支付
        addCar() {
            let form = {}
            if(this.checkedBalance){
                this.$set(form, 'balanceAmount', this.balance)
            }
            this.$set(form, 'invId', this.invId)
            this.$set(form, 'quantity', this.num)
            this.$set(form, 'payAmount', (this.realSubTotal-0))
            if(this.goods.way === 1 && this.mobile === '') {
                this.$help.error('请填写手机号')
                return
            }else if(this.goods.way === 1){
                this.$set(form, 'phone', this.mobile)
            }
            if(this.goods.way === 1 && this.address === '') {
                this.$help.error('请填写收货地址')
                return
            }else if(this.goods.way === 1){
                this.$set(form, 'receivingAddress', this.address)
            } 
            //console.log(this.goods)
            this.$api.post('member/bill/shoppingOrder/shoppingOrder-save', form).then(res=>{
                //console.log(res)
                let url = window.encodeURIComponent(`${window.location.origin}/#/center/pay?orderId=${res.id}&orderType=${res.orderPayInfoVO.orderType}`)
                window.location.href = `${res.orderPayInfoVO.jumpGetOpenIdUrl}?returnUrl=${url}`
            })
        },
        onChageInputBa() {
            if(this.balanceTotal > this.subtotal*this.conversionBa){
                //够
                if((this.$refs.inputs.value-0) >= this.subtotal*this.conversionBa){
                    this.$refs.inputs.value = this.subtotal*this.conversionBa
                    this.balance = this.subtotal*this.conversionBa
                }else{
                    this.balance = this.$refs.inputs.value 
                }
            }else{
                //不够
                if((this.$refs.inputs.value-0) >= this.balanceTotal){
                    this.$refs.inputs.value = this.balanceTotal
                    this.balance = this.balanceTotal
                }else{
                    this.balance = this.$refs.inputs.value 
                }
            }
        },
        changeNum() {
            let balance = this.subtotal*this.conversionBa
            if( balance >= this.balanceTotal){
                this.balance = this.balanceTotal
            }else{
                this.balance = balance
            }
        },
        goShopCar() {
            this.$router.push('/shopping')
        },
        getMemberUser() { 
            this.$store.dispatch('getMemberUser').then(() => {
                this.balanceTotal = this.$store.state.memberUser.amount
                this.address = this.$store.state.memberUser.receivingAddress
                this.mobile = this.$store.state.memberUser.mobile
            })
        }
    },
    mounted() {
        this.$set(this.model, 'status', 1)
        this.$set(this.model, 'invType', '01')
        this.getLevel()
        if(this.$help.storeTemp.get('personType') === 0) {
            //会员
            this.getMemberUser()

        }
        this.init()
    }
    
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .my-mall{
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
        &__header{
            display: flex;
            margin: 10px 20px;
            background-color: $yellow;
            padding: 15px;
            border-radius: 19px;
            justify-content: space-between;
            align-items: center;
            font-weight: bold;
            .shopping{
                font-size: 28px;
                position: relative;
                .my-badge{
                    position: absolute;
                    top: 4px;
                    left: 72px;
                }
            }
            .settlement{
                padding: 8px 12px;
                background-color: #fff;
                color: $main;
                border-radius: 9px;
           }
        }
        &__search {
            // margin: 40px 0 20px;
            padding: 0 20px 0 8px;
            background-color: #fff;
            .van-search__action{
                color: $main;
            }
        }
        .content{
            display: flex;
            justify-content: space-between;
            .van-sticky--fixed{
                right: unset;
                top: 60px;
            }
            .left{
                flex: 1;
                position: fixed;
                height: calc(100vh - 130px);
                overflow: scroll;
                z-index: 88;
                margin-top: 15px;
                //width: 80px;
                .line{
                    width: 20px;  
                    margin: 0 auto;  
                    line-height: 15px;  
                    font-size: 14px;
                    padding: 10px;
                    color: $main;
                    opacity: .3;
                    font-size: 12px;
                }
                .action {
                    opacity: 1 !important;
                }
            } 
            .my-pull-refresh{
                flex: 7;
                .my-list{
                    margin-top: 20px;
                    padding: 0 25px 0 50px;
                    //margin-left: 40px;
                }
            }

        }
        .my-popup{
            border-radius: 19px;
            .goods {
                min-height: 65vh;
                width: 280px;
                border-radius: 19px;
                padding: 20px;
                &-info{
                    &__img{
                        border-radius: 19px;
                        .my-image{
                            height: 200px;
                            width: 100%;
                        }
                        img{
                            border-radius: 19px;
                        }
                    }
                    &__desc{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 5px 10px;
                        .money{
                            min-width: 80px;
                            padding: 10px;
                            border-radius: 9px;
                            background-color: $main;
                            color: #fff;
                            font-weight: bold;
                            text-align: center;
                            span{
                                font-weight: 400;
                                font-size: 10px;
                            }
                        }
                        .desc{
                            margin-left: 20px;
                            width: 180px;
                            font-size: 12px;
                             display:-webkit-box;/**对象作为伸缩盒子模型展示**/
                            -webkit-box-orient:vertical;/**设置或检索伸缩盒子对象的子元素的排列方式**/
                            -webkit-line-clamp:2;/**显示的行数**/
                            overflow:hidden;/**隐藏超出的内容**/
                        }
                    }
                }
                .total{
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    &-money{
                        color: $red;
                        font-size: 14px;
                    }
                }
                .van-stepper{
                    
                    display: flex;
                    justify-content: flex-end
                }
                .integral{
                    padding: 10px 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top: 1px solid #ededed;
                    border-bottom: 1px solid #ededed;
                    .int{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .color{
                            color: $pink;
                        }

                    }
                    .input{
                        border: 0;
                        color: $pink;
                        outline: none;
                        border-bottom: 1px solid $pink;
                        width: 80px;
                        text-align: center;
                    }
                    .van-checkbox__icon--checked .van-icon{
                        background-color: $main;
                        border-color: $main;
                    }
                    // span{
                    //     color: $pink;
                    // }
                }
            }
            .btn{
                margin-top: 20px;
                .car{
                    background-color: $yellow;
                    border-radius: 9px;
                    text-align: center;
                    padding: 10px 0;
                    font-size: 16px;
                    //color: #fff;
                    //font-weight: bold;
                }
                .close{
                    text-align: center;
                    padding: 10px 0;
                }
            }
            
        }
       

    }
</style>
<style lang="scss">

    @import '~@/assets/css/var';
    .img_upload{
        .my-upload {
            flex: 1;
            margin-bottom: 20px;
            vertical-align: top;
            padding: 10px 0 0 10px;
            .van-uploader__wrapper { 
                justify-content: start;
                .van-uploader__preview{
                    margin-right:10px;
                    margin-bottom: 10px;
                }
            }
            .van-uploader__upload{
                border: 1px dotted #ccc;
            }
            .van-uploader__upload,  .van-uploader__preview-image, .van-uploader__file {
                width: 100px;
                height: 100px;
                //margin-bottom:10px;
            }
            .video{
                video{
                    width:100px;
                    height:100px;
                    object-fit:cover; 
                }
            }
        }
        .video_popup{
            video{
                width: 100%; 
                height: 100%;
                object-fit: contain;
            }
        }
    }

</style>
<template>
    <div class="img_upload">
        <van-uploader class="my-upload" v-model="videoList" multiple accept="video/*" 
            :after-read="afterRead" 
            :before-read="beforeRead" 
            @click-preview="handleclicksc"
            :upload-text="desc" @delete="deleteImg" deletable 
        >
            <template slot-scope="props" slot="preview-cover">
                <div class="video"><img :src="props.url" alt=""></div>
            </template>
        </van-uploader>

        <div>
        </div>
        <!-- 弹框预览视频 -->
        <van-popup class="video_popup" v-model="showvideoplay"
            style="width:100%;border-radius :0; height: 200px" theme="default" >
            <video controls preload="auto" :src="videourl" v-if="videourl"></video>
        </van-popup>
    </div>
</template>

<script>
    export default {
        props: {
            fileList:{
                type:Array,
                default() {
                    return []
                }
            },
            desc: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                carinfo: {
                    videofile: '',
                    ngEcoOpenNo: ''
                },
                videoList: [],
                strList: [],
                showvideoplay: false,
                videourl: '',
            }
        },
        created() {
            this.videoList = this.fileList
        },
        methods: {
            beforeRead (e) {
                if (e.size > 104857600) {
                    this.$help.error("选择/录制视频不能超过100M");
                    return false;
                }
                return true;
            },
            afterRead(file) {
                file.status = 'uploading'
                file.message = '上传中'
                let formData = new FormData();
                formData.append("file",file.file,file.file.name);

                if (this.type) {
                    formData.append("fileType", this.type);
                }
               
                this.$api.post('sysAttachment', formData, {'Content-Type': 'multipart/form-data'})
                    .then(res => {
                        this.strList.push(res.id)
                        file.status = '';
                        //console.log(this.handleFile(this.pictureList))
                        this.getVideoBase64(this.getImg(res.id)).then(res=>{
                            this.$set(file, 'url', res)
                        })
                        this.$emit("getVideo", this.handleFile(this.strList))
                    })
                    .catch(() => {
                        file.status = 'failed'
                        file.message = '上传失败'
                    })
            },
            getVideoBase64(url) {
                return new Promise((resolve) => {

                    let dataURL = '';
                    let video = document.createElement("video");

                    // 处理跨域
                    video.setAttribute('crossOrigin', 'anonymous');
                    video.setAttribute('src', url);
                    video.setAttribute('width', 100);
                    video.setAttribute('height', 100);
                    video.setAttribute('autoplay', 'autoplay');
                    video.pause();
                    video.addEventListener('loadeddata', () => {
                        // 视频时长video.duration;
                        let canvas = document.createElement("canvas"),
                            //canvas的尺寸和图片一样
                            width = video.width, 
                            height = video.height;

                        canvas.width = width;
                        canvas.height = height;
                        // 绘制canvas
                        canvas.getContext("2d").drawImage(video, 0, 0, width, height);
                        // 转换为base64
                        dataURL = canvas.toDataURL('image/jpeg'); 
                        resolve(dataURL);
                    });
                });
            },
            handleclicksc(file) {
                this.videourl = file.content
                this.showvideoplay = true;
            },
            deleteImg(file, index) {
                this.strList.splice(index.index, 1)
                //console.log(this.handleFile(this.pictureList))
                this.$emit("getVideo", this.handleFile(this.strList))
            },

            handleFile(arr){
                return arr.toString()
            },

            setValue(){
                this.videoList = []
            }
           
        },
        mounted() {
           
        }
    }
</script>
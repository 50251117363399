<template>
    <div class="My-Course" :class="{'my-complete': complete}" >
        <div class="course">
            <i class="iconfont icon-banjishangke" @click="$emit('goToCourse')" v-if="item.curriculumTimeStatus === 2 && $help.storeTemp.get('personType') === 1"></i>
            <div class="course-code ellipsis">
                {{item.billCurriculumTimeCode}}
            </div>
            <div class="info">
                <div class="label">日期</div>
                <div class="value">{{item.billDate}}</div>
            </div>
            <div class="info time">
                <div class="label">时段</div>
                <div class="value">{{item.begTime}}~{{item.endTime}}</div>
            </div>
            <slot name="type"></slot>
            <div class="info mall">
                <div class="label">马场</div>
                <div class="value">{{item.corpName}}</div>
            </div>
            <!-- <div class="info">
                <div class="color">
                    <van-icon name="location-o" />
                    500米
                </div>
                <div>
                    <van-icon name="guide-o" />
                </div>
            </div> -->
            <!-- 教练评价 -->
            <div class="info no-flex" v-if="item.coachComment && $help.storeTemp.get('personType') !== 2">
                <div class="label">课程评价</div>
                <div class="value">{{item.coachComment}}</div>
            </div>
            <div class="info no-flex" v-if="(item.rankScore || item.rankScore === 0) && $help.storeTemp.get('personType') !== 2">
                <div class="label">课程评分</div>
                <van-rate readonly v-model="item.rankScore" color="#ffd21e" 
                    void-color="#eee" :size="25" icon="star" void-icon="star-o" />
            </div>
            <div class="info info-btn" v-if="$help.storeTemp.get('personType') !== 2">
                <van-button v-if="item.curriculumTimeStatus === 4" round @click="$emit('goAppraise')">评价</van-button>
            </div>
            <!-- 点击完成上课 -->
            <div class="info info-btn" v-if="$help.storeTemp.get('personType') === 1">
                <van-button v-if="item.curriculumTimeStatus === 3" round @click="$emit('goComputed')">完成上课</van-button>
            </div>
            <!-- <div class="info info-btn" v-if="$help.storeTemp.get('personType') === 1">
                <van-button v-if="item.curriculumTimeStatus === 2" @click="$emit('goAppraise')">按钮</van-button>
            </div> -->
            <div v-if="item.curriculumTimeStatus === 6">
                <div class="info">
                    <div class="label appraise">评语</div>
                    <div class="value">{{item.abilityComment}}</div>
                </div>
                <div class="info">
                    <div class="label">能力评分</div>
                    <div class="value">{{item.abilityScore}}</div>
                </div>
                <div class="info">
                    <div class="label">态度评分</div>
                    <div class="value">{{item.attitudeScore}}</div>
                </div>
            </div>
            <div class="circle left-top"></div>
            <div class="circle left-bottom"></div>
            <div class="circle right-top"></div>
            <div class="circle right-bottom"></div>
            <!-- <div class="status">待支付</div> -->
        </div>
    </div>
</template>

<script>
export default {
    props:{
        complete: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data(){
        return {
        }
    },
    methods:{

    },
    mounted() {
        //console.log(this.item)
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var.scss';
    .My-Course{
        .course{
            margin-right: 25px;
            box-shadow: 0 0 8px 6px $boxShadow;
            border-radius: 9px;
            padding: 10px;
            position: relative;
            overflow: hidden;
            background-color: #fff;
            .iconfont{
                position: absolute;
                right: 10px;
                top: 15px;
                color: $main;
            }
            &-code{
                color: $gray;
                line-height: 35px;
                width: 180px;
            }
            .info{
                display: flex;
                justify-content: space-between;
                line-height: 30px;
                .appraise{
                    width: 80px !important;
                }
                .label{
                    color: $gray;
                }
                .van-icon-arrow {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color:#DEEDFC;;
                    text-align: center;
                    line-height: 20px;
                    color:#3992EA;
                    margin-left:5px;
                }
                .color{
                    color: $pink;
                }   
                i{
                    color: $main !important;
                }
            } 
            .no-flex {
                flex-direction: column;
            }
            .coach, .mall{
                padding-top: 10px;
                border-top: 1px dashed #ccc;
            }
            .info-btn{
                display: flex;
                justify-content: flex-end;
                .van-button{
                    height: 24px;
                    border: 0;
                    color: $main;
                }
            }
            .level, .time {
                padding-bottom: 10px;
            }
            .status{
                line-height: 45px;
                width: 45px;
                text-align: center;
                border-radius: 50%;
                border: 1px solid $pink;
                color: $pink;
                position: absolute;
                top: -8px;
                right: -8px;
                transform: rotate(45deg);
            }
            .circle{
                height: 20px;
                width: 20px;
                position: absolute;
                border-radius: 50%;
                background-color: $boxShadow;
            }
            .left-top{
                top: 104px;
                left: -10px;
            }
            .left-bottom{
                top: 186px;
                left: -10px;
            }
            .right-top{
                top: 104px;
                right: -10px;
            }
            .right-bottom{
                top: 186px;
                right: -10px;
            }
        } 
        
    }
    .my-complete{
        .course{
            background-color: #3992EA;
            .info{
                .value{
                    color: #fff !important;
                }
                i{
                    color: #fff !important;
                }
                .van-icon-arrow{
                    background-color: unset !important;
                }
                .van-rate__item{
                    i{
                        color: #ffd21e !important;
                    }
                }
            }
        }
    }
</style>
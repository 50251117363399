import {Toast, Notify, Dialog} from "vant"

// region 日期格式化
function timeFormat(time, fmt) {
    const o = {
        "M+": time.getMonth() + 1, //月份
        "d+": time.getDate(), //日
        "h+": time.getHours(), //小时
        "m+": time.getMinutes(), //分
        "s+": time.getSeconds(), //秒
        "q+": Math.floor((time.getMonth() + 3) / 3), //季度
        "S": time.getMilliseconds() //毫秒
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (time.getFullYear() + "").substr(4 - RegExp.$1.length))
    }
    for (const k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt || '1970-01-01 00:00:00'
}
Date.prototype.Format = function (fmt = 'yyyy-MM-dd hh:mm:ss') {
    return timeFormat(this, fmt)
}
Date.prototype.FormatShort = function () {
    return timeFormat(this, 'yyyy-MM-dd')
}
String.prototype.Format = function (fmt = 'yyyy-MM-dd hh:mm:ss') {
    if(isNaN(this) && !isNaN(Date.parse(this))){
        return timeFormat(new Date(this), fmt)
    }
}
String.prototype.FormatShort = function () {
    if(isNaN(this) && !isNaN(Date.parse(this))){
        return timeFormat(new Date(this), 'yyyy-MM-dd')
    }
}
// endregion

const clone = (obj) => {
    let o
    if (typeof obj === 'object') {
        if (obj === null) {
            o = null;
        } else {
            if (obj instanceof Array) {
                o = [];
                for (let i = 0, len = obj.length; i < len; i++) {
                    o.push(clone(obj[i]));
                }
            } else if (obj instanceof Date) {
                o = new Date(obj)
            } else {
                o = {};
                for (let j in obj) {
                    o[j] = clone(obj[j]);
                }
            }
        }
    } else {
        o = obj;
    }
    return o;
}

const joinObj = function() {
    const obj = {}
    for (let i = 0; i < arguments.length; i++) {
        Object.assign(obj, arguments[i])
    }
    return obj
}

const store = {
    get(key) {
        const value = localStorage.getItem(key)
        if (value) {
            try {
                const value_json = JSON.parse(value)
                if (typeof value_json === 'object') {
                    return value_json
                } else if (typeof value_json === 'number') {
                    return value_json
                }
            } catch(e) {
                return value
            }
        } else {
            return false
        }
    },
    set(key, value) {
        if (typeof value === 'object') {
            localStorage.setItem(key, JSON.stringify(value))
        } else {
            localStorage.setItem(key, value)
        }
    },
    remove(key) {
        localStorage.removeItem(key)
    },
    clear() {
        localStorage.clear();
    }
}

const tokenHandle = {
    get() {
        return store.get('MEMBER')
    },
    set(token) {
        store.set('MEMBER', token)
    },
    remove() {
        store.remove('MEMBER')
    },
}



export default {
    // 深拷贝
    deepClone: clone,

    setTitle(title) {
        document.title = title;
        let userAgent = window.navigator.userAgent.toLowerCase();
        let isiOS = userAgent.indexOf('applewebkit') >= 0;
        let isWechat = userAgent.indexOf('micromessenger') >= 0;
        if (isiOS && isWechat) {
            let iframe = document.createElement('iframe');
            iframe.src = 'https://www.baidu.com/favicon.ico';
            iframe.style.display = 'none';
            document.body.appendChild(iframe);
            iframe.onload = function() {
                setTimeout(function() {
                    iframe.remove();
                }, 0)
            }
        }
    },

    // 合并参数
    joinObj,
    // storage操作
    store,
    storeTemp: {
        get(key) {
            const value = sessionStorage.getItem(key)
            if (value) {
                try {
                    const value_json = JSON.parse(value)
                    if (typeof value_json === 'object') {
                        return value_json
                    } else if (typeof value_json === 'number') {
                        return value_json
                    }
                } catch(e) {
                    return value
                }
            } else {
                return false
            }
        },
        set(key, value) {
            if (typeof value === 'object') {
                sessionStorage.setItem(key, JSON.stringify(value))
            } else {
                sessionStorage.setItem(key, value)
            }
        },
        remove(key) {
            sessionStorage.removeItem(key)
        },
        clear() {
            sessionStorage.clear();
        }
    },

    token: tokenHandle,


    loading(message = '加载中...') {
        Toast.loading({
            message,
            forbidClick: true,
            overlay: true,
            duration: 0
        })
    },
    loadingIdCord(message = '身份证识别中...') {
        Toast.loading({
            message,
            forbidClick: true,
            overlay: true,
            duration: 0
        })
    },
    loaded() {
        Toast.clear()
    },
    error(msg) {
        Notify({ type: 'warning', message: msg });
    },
    success(msg) {
        Notify({ type: 'success', message: msg })
    },
    confirm(option) {
        if (typeof option === 'string') {
            option = {
                title: '提示',
                message: option,
            }
        }
        return  Dialog.confirm(option)
    },
    addPageAttachSpreadId(vue, form) {
        if (vue.$route.query.itemApreadId) {
            form.itemApreadId = vue.$route.query.itemApreadId
        }
    },
    tobdMap(x, y) {
        let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
        let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
        let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
        let bd_lon = z * Math.cos(theta) + 0.0065;
        let bd_lat = z * Math.sin(theta) + 0.006;
        return [bd_lon.toFixed(6), bd_lat.toFixed(6)]
    }
}
<template>
  <div class="my-screen">
    <van-popup v-model="screenObj.showPop" position="right" :style="{ height: '100%',width: '80%' }" closeable>
        <van-cell-group :border="false">
            <div class="input_group">
                <div class="input" v-for="(item,index) in inputList" :key="index">
                    <div class="label">
                        {{item.label}}
                    </div>
                    <div class="inp">
                        <input :type="item.number?'number':'text'"  :placeholder="item.placeholder" v-model="item.key" :readonly="item.readonly" @click="getInfo(item.readonly)">
                    </div>
                </div>           
            </div>
        </van-cell-group>
        <div class="btn">
            <van-button type="warning" @click="reSet">重置</van-button>
            <van-button type="primary" @click="onSure">确认</van-button>
        </div>
    </van-popup>
    <!-- 日期 年月日 -->
    <van-popup v-model="showDate" position="bottom" round>
      <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          @confirm="getDate"
          @cancel="showDate = false"
          :min-date="minDate"
          :max-date="maxDate"
      />
    </van-popup>
    <!-- 详细日期 年月日-时分秒 -->
    <van-popup v-model="showDateTime" position="bottom" round>
      <van-datetime-picker
          v-model="currentDate"
          type="datetime"
          title="选择年月日"
          @confirm="getDateTime"
          @cancel="showDateTime = false"
          :min-date="minDate"
          :max-date="maxDate"
      />
    </van-popup>
    <!-- 类型 -->
    <van-popup v-model="showPicker" round position="bottom">
        <van-picker
            show-toolbar
            :columns="columns"
            @cancel="showPicker = false"
            @confirm="selectType"
        />
    </van-popup>
    <!-- 状态 -->
    <van-popup v-model="showStatus" round position="bottom">
        <van-picker
            show-toolbar
            :columns="columnsStatus"
            @cancel="showStatus = false"
            @confirm="selectStatus"
        />
    </van-popup>
    <!-- 结算周期 -->
    <van-popup v-model="showCycle" round position="bottom">
        <van-picker
            show-toolbar
            :columns="settleCycle"
            @cancel="showCycle = false"
            @confirm="selectCycle"
        />
    </van-popup>
    <!-- 结算方式 -->
    <van-popup v-model="showWay" round position="bottom">
        <van-picker
            show-toolbar
            :columns="settleWay"
            @cancel="showStatus = false"
            @confirm="selectWay"
        />
    </van-popup>
    <!-- 服务类型 -->
    <van-popup v-model="showType" round position="bottom">
        <van-picker
            show-toolbar
            :columns="serviceType"
            @cancel="showType = false"
            @confirm="selectServiceType"
        />
    </van-popup>
    <!-- 日历 日期范围 -->
    <van-calendar v-model="showTime" type="range"  :min-date="minDate" :max-date="maxDate" @confirm="onConfirm" color="$main"/>
  </div>
</template>

<script>
export default {
    props:{
        inputList:{
            type: Array,
            default: () => {
                return []
            }
        },
        screenObj:{
            type: Object,
            default: () => {
                return {}
            }
        },
        columns:{
            type: Array,
            default:()=>{
                return []
            }
        },
        columnsStatus:{
            type: Array,
            default: ()=>{
                return []
            }
        },
        settleCycle: {
            type: Array,
            default: ()=>{
                return []
            }
        },
        settleWay: {
            type: Array,
            default: ()=>{
                return []
            }
        },
        serviceType: {
            type: Array,
            default: ()=>{
                return []
            }
        }
    },
    data() {
        return {
            params:{},
            minDate: new Date(2000, 10, 1),
            maxDate: new Date(2060, 0, 31),
            currentDate:'',
            showDate:false,
            showDateTime:false,
            showPicker:false,
            showStatus:false,
            showTime:false,
            showCycle:false,
            showWay:false,
            showType:false
        }
    },
    methods:{
        getInfo(type) {
            if(type === 'extDate'){
                this.showDate = true
            }
            if(type === 'dateTime'){
                this.showDateTime = true
            }
            if(type === 'eventLevel'){
                this.showPicker = true
            }
            if(type === 'itemStatus'){
                this.showStatus = true
            }
            if(type === 'itemTaskDate'){
                this.showTime = true
            }
            if(type === 'itemSettleType'){
                this.showWay = true
            }
            if(type === 'itemSettlePeriod'){
                this.showCycle = true
            }
            if(type === 'itemServiceType'){
                this.showType = true
            }
        },
        onConfirm(date) {
            const [start, end] = date;
            let startDate = new Date(start).FormatShort()
            let endDate = new Date(end).FormatShort()
            this.$set(this.params, 'itemTaskDateBeg', startDate)
            this.$set(this.params, 'itemTaskDateEnd', endDate)
             this.inputList.map(ele=>{
                if(ele.readonly === 'itemTaskDate'){
                    ele.key = startDate + '~' + endDate
                }
            })
            this.showTime = false;
        },
        getDateTime(value){
            this.inputList.filter(ele=>{
                if(ele.readonly === 'dateTime'){
                    ele.key = new Date(value).Format()
                    this.$set(this.params, ele.field, `%${ele.key}%`)
                }
            })
            this.showDateTime = false;
        },
        getDate(value){
            this.inputList.map(ele=>{
                if(ele.readonly === 'extDate'){
                    ele.key = new Date(value).FormatShort()
                    this.$set(this.params, ele.field, `%${ele.key}%`)
                }
            })
            this.showDate = false;
        },
        selectType(value){
            this.inputList.map(ele=>{
                if(ele.readonly === 'eventLevel'){
                    ele.key = value
                    this.$set(this.params, 'eventLevel', value)
                }
            })
            this.showPicker = false
            //console.log(value,i)
        },
        selectStatus(value, i){
            this.inputList.map(ele=>{
                if(ele.readonly === 'itemStatus'){
                    ele.key = value
                    this.$set(this.params, ele.field, i)
                }
            })
            this.showStatus = false
            //console.log(value,i)
        },
        selectCycle(value, i){
            this.inputList.map(ele=>{
                if(ele.readonly === 'itemSettlePeriod'){
                    ele.key = value
                    this.$set(this.params, 'itemSettlePeriod', i)
                }
            })
            this.showCycle = false
            //console.log(value,i)
        },
        selectWay(value, i){
            this.inputList.map(ele=>{
                if(ele.readonly === 'itemSettleType'){
                    ele.key = value
                    this.$set(this.params, 'itemSettleType', i)
                }
            })
            this.showWay = false
            //console.log(value,i)
        },
        selectServiceType(value) {
            this.inputList.filter(ele=>{
                if(ele.readonly === 'itemServiceType'){
                    ele.key = value
                    this.$set(this.params, 'itemServiceType', value)
                }
            })
            this.showType = false
        },
        reSet() {
            this.inputList.map(item=>{
                item.key = ''
            })
        },
        onSure() {
            //分页接口的params
            this.inputList.map(ele=>{
                //if(ele.)
                if(ele.key !=='' && !ele.readonly){
                    this.$set(this.params, ele.field, ele.key)
                }
                if(ele.number && ele.key !==''){
                    this.$set(this.params, ele.field, ele.key)
                }
            })
            this.$emit('onSure',this.params)
        },
        setValue(value){
            this.inputList.map(item=>{
                item.key = ''
                item.type = ''
            })
            this.params = value
        }
    },
    mounted(){
        this.currentDate = new Date()
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var.scss';
    .my-screen{
        .btn{ 
            position: absolute;
            bottom: 10px;
            left:50%;
            transform: translateX(-50%);
            width:80%;
            z-index: 88888;
            box-shadow:0 0 9px 3px $boxShadow;
            border-radius: 28px;
        .van-button{
            width:50%;
        }
        .van-button--warning{
            background-color: #fff;
            color:$main;
            border:0px;
            border-radius: 28px 0 0 28px;
        }
        .van-button--primary{
            background-color: $main;
            border:1px solid $main;
            color:#fff;
            border-radius: 0 28px 28px 0;
        }
        }
        .van-field__label {
            margin: 0;
        }
        .input_group{
            margin-top:50px;
            padding:0 25px;
            max-height: 82vh;
            overflow: scroll;
            .van-cell{
                width: 100%;
                padding:4px 10px;
                background-color: #F4F5F9;
                border-radius: 19px;
                margin-top:10px ;
                input{
                    color:#999;
                    font-size: 12px;
                }
            }
            .van-cell__title{
                color:#A8BCDA;
                height:24px;
                font-size:12px;
            }
            .input{
                padding:10px 0;
                margin-top: 10px;
                .label{
                    color: #C1C1CA;
                }
                .inp{
                    input{
                        border:none;
                        background-color:#F4F5F9;
                        border-radius: 19px;
                        height:30px;
                        width: 94%;
                        padding-left: 10px;
                        color:$main; 
                        margin-top: 10px; 
                        font-size: 12px;
                    }
                }
                ::-webkit-input-placeholder{
                    color:#A8BCDA;
                }
            }
        }
        .van-popup__close-icon--top-right{
            color: $red;
        }
    }
</style>
import Vue from 'vue'

import MyUpload from '@/components/MyUpload'
import MyVideo from '@/components/MyVideo'
import MyHeader from '@/components/MyHeader'
import MyAdd from '@/components/MyAdd'
import MyItem from '@/components/MyItem'
import MyMap from '@/components/MyMap'

import ImgUpload from '@/components/ImgUpload'
import NoMore from '@/components/NoMore'
import NoData from '@/components/NoData'
import MyScreen from '@/components/MyScreen'
import MyTitle from '@/components/MyTitle'
import MyBtn from '@/components/MyBtn'
import VideoItem from '@/components/VideoItem'
import MyCourse from '@/components/MyCourse'
import MyVoucher from '@/components/MyVoucher'
import CheckStand from '@/components/CheckStand'


Vue.component('MyUpload', MyUpload)
Vue.component('MyVideo', MyVideo)
Vue.component('MyHeader', MyHeader)
Vue.component('MyAdd', MyAdd)
Vue.component('MyMap', MyMap)
Vue.component('MyItem', MyItem)
Vue.component('ImgUpload', ImgUpload)
Vue.component('NoMore', NoMore)
Vue.component('NoData', NoData)
Vue.component('MyScreen', MyScreen)
Vue.component('MyTitle', MyTitle)
Vue.component('MyBtn', MyBtn)
Vue.component('VideoItem', VideoItem)
Vue.component('MyCourse', MyCourse)
Vue.component('MyVoucher', MyVoucher)
Vue.component('CheckStand', CheckStand)
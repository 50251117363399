import Vue from 'vue'

Vue.directive('date-input', {
    bind(el) {
        el.addEventListener('click', function () {
            if (el.className.indexOf('van-field--disabled') === -1) {
                const input = el.getElementsByTagName('input')
                window.eventBus.$emit('checkDate', {
                    cb: date => {
                        this.value = date

                        if (el.__vue__) {
                            el.__vue__.$emit('input', date)
                        } else {
                            el.dispatchEvent(new Event('input'))
                        }

                    },
                    val: (input && input.length === 1) ? input[0].value : '',
                })
            }
        })
    }
})

Vue.directive('datetime-input', {
    bind(el) {
        el.addEventListener('click', function () {
            if (el.className.indexOf('van-field--disabled') === -1) {
                const input = el.getElementsByTagName('input')
                window.eventBus.$emit('checkDatetime', {
                    cb: date => {
                        this.value = date

                        if (el.__vue__) {
                            el.__vue__.$emit('input', date)
                        } else {
                            el.dispatchEvent(new Event('input'))
                        }

                    },
                    val: (input && input.length === 1) ? input[0].value : '',
                })
            }
        })
    }
})